import React from 'react'
import { Row } from 'react-bootstrap'
import Loader from '../../../Components/Shared/Loader'
import Table, { IColumn } from '../../../Components/Shared/Table'
import { IRankedLaptime } from '../../../Models'

const DataTable = ({loading, columns, leaderBoardResults}: {loading: boolean, columns: IColumn<IRankedLaptime>[], leaderBoardResults: IRankedLaptime[]}) => {
    return (
        <Row style={{ marginTop: "40px", height: "55vh", overflowY:'scroll' }}>
            <Loader loading={loading}>
                <Table columns={columns} data={leaderBoardResults} />
            </Loader>
        </Row>
    )
}

export default DataTable