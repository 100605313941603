import { create } from "zustand";
import { IUser } from "./UserStore";

interface IUserLookupStore {
    user: IUser|null,
    setUser: (v: IUser|null)=>void
}

export const useUserLookupStore = create<IUserLookupStore>()((set) => ({
    user: null,
    setUser: (u: IUser|null)=> set({user: u})
}))