import React from 'react'
import { useTable } from "react-table";
import { colors } from "../../../styles/colors";

const Table = ({ columns, data }: {columns: {Header:string, accessor:string, width: string}[], data: any[]}) => {
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data
    });

    return (
    <table {...getTableProps()} style={{ marginTop: '20px', borderRadius:'10px', width: '100%' }}>
        <thead>
            {
                headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                            headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    style={{
                                        borderBottom: `solid 2px ${colors.borderLight}`,
                                        background: colors.white,
                                        color: 'black',
                                        fontSize: '15px',
                                        fontWeight: 'normal',
                                        borderRadius:'10px',
                                        width: column.width
                                    }}
                                >
                                    {column.render('Header')}
                                </th>
                            ))
                        }
                    </tr>
                ))
            }
        </thead>
        <tbody {...getTableBodyProps()}>
            {
                rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {
                                row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            style={{
                                                padding: '5px',
                                                border: `solid 1px ${colors.borderLight}`,
                                                background: colors.white,
                                                borderRadius:'10px',
                                                fontSize: '14px',
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })
                            }
                        </tr>
                    );
                })
            }
        </tbody>
    </table>
    );  
};  

    export default Table