import React from 'react'
import { Page } from '../Components/Shared/Page'

export const Medals = () => {

    return (
        <Page>
            Medals (coming soon)
        </Page>
    )
}