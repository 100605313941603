import React, { InputHTMLAttributes } from 'react'
import { colors } from "../../styles/colors"
import Text from './Text'

const TextInput = ({type, title, value, setValue, color = colors.black}:{type: InputHTMLAttributes<HTMLInputElement>['type'], title: string, value: string, setValue: (v:string)=>void, color?: string}) => {
    return (
        <div style={{marginBottom:'25px'}}>
            <Text align='left' text={title} color={colors.white}/>
            <input
            style={{
                width: '100%',
                padding: '10px',
                border:'solid grey 1px',
                color:color,
                backgroundColor: 'transparent'
            }}
            type={type}
            value={value}
            onChange={(e) => {
                setValue(e.target.value)
            }}
            required
        />
        </div>
    )
}

export default TextInput