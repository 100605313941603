import React, { useEffect, useState } from 'react'
import { useUserStore } from '../../stores/UserStore'
import { useSupabaseClientStore } from '../../stores/supabaseClientStore'
import { useNavigate } from 'react-router-dom'
import DashboardItem from './DashboardItem'
import { Button, Col, Image, Row } from 'react-bootstrap'
import Text from '../../Components/Shared/Text'
import { colors } from '../../styles/colors'
import { useToastStore } from '../../stores/PopToastStore'
import Loader from '../../Components/Shared/Loader'
import { IUserResponse } from '../../Models'

const usericon = require('../../Assets/iconusergrey.png')
const searchicon = require('../../Assets/iconsearchgrey.png')
const defaultImage = require('../../Assets/imgdefault.png')

const Users = () => {

    const [searchVal, setSearchVal] = useState('')
    const [searchedUsers, setSearchedUsers] = useState<IUserResponse[]>([])

    const user = useUserStore(state=>state.user)
    const supabase = useSupabaseClientStore(state=> state.supabaseClient)

    const [loading, setLoading] = useState(true)

    const nav = useNavigate()

    interface IUserResponse {
        bio: string | null;
        coverimage: string | null;
        created_at: string;
        id: string;
        localtrack: number | null;
        name: string | null;
        nickname: string | null;
        profileimage: string | null;
        team1: number | null;
        team2: number | null;
        team3: number | null;
        usertype: number | null;
        following?:boolean
    }

    const search = async () => {
        setLoading(true)
        if(user){
            
            let results: IUserResponse[]|null|undefined = []

            if(searchVal.length > 0) {
                results = (await supabase.from('Profiles').select('*').neq('id', user.id).textSearch('name', searchVal).limit(10)).data
            } else {
                results = (await supabase.from('Profiles').select("*").neq('id', user.id).limit(10)).data
            }

            let followData = (await supabase.from('Follow').select("*").eq('user_from', user.id)).data?.map(x => x.user_to)

            results = results?.map(x => {
                return {
                    ...x,
                    bio: (x.bio?.slice(0,50) ?? '') + ' ...',
                    following: followData?.includes(x.id) ? true : false
                }
            })

            if(results) {
                if(results.length > 0) {
                    for(let x of results) {
                        if(x.profileimage) x.profileimage = (await supabase.storage.from('profilepictures').getPublicUrl(x.profileimage ?? '')).data.publicUrl
                    }
                }
            }
            setSearchedUsers(results ?? [])
            setLoading(false)
        }
    }

    useEffect(() => {
        search()
    }, [])

    const linkToProfile = (id: string) => {
        nav(`/userprofile/${id}`)
    }

    return (
        <DashboardItem height={900}>
            <Row style={{width:'100%'}}>
                <Col style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                    <Text align='left'  bold text={'Find Users'}/>
                </Col>
                <Col style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                    <Image width={22} height={22} src={usericon}/>
                </Col>
            </Row>
            <Row style={{marginTop:'20px'}}>
                <Col style={{height:'35px', border: `solid ${colors.borderLight} 1px`, borderRadius:'5px', display:'flex', alignItems:'center'}}>
                    <Row>
                        <Col xs='2' >
                            <Image width={20} height={20} src={searchicon}/>
                        </Col>
                        <Col xs='10'>
                            <input type='text' placeholder='input name' style={{color: colors.textLight, height:'100%', width:'100%', border:'none', outline:'none'}} value={searchVal} onChange={(e) => {setSearchVal(e.target.value)}}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop:'5px'}}>
                <Col style={{padding:'0px'}}>
                    <Button 
                        onClick={() => search()}
                        style={{fontWeight:'bold', fontSize:'14px', height:'35px', width:'100%', backgroundColor: colors.darkBlue, border:'none'}}
                    >
                        Search
                    </Button>
                </Col>
            </Row>
            <Row style={{marginTop:'20px', borderBottom:`solid ${colors.borderLight} 1px`, borderTop:`solid ${colors.borderLight} 1px`, paddingTop:'10px', paddingBottom:'10px'}}>
                <Text italic color={colors.backgroundDark} text={`Showing ${searchedUsers.length} users - Use the search box to narrow down your search`}/>
            </Row>
            <Row style={{marginTop:'10px', height:'320px'}}>
                <Loader loading={loading}>
                    <Col style={{padding:'0px'}}>
                        <div style={{whiteSpace:'nowrap', width:'100%', display:'flex', justifyContent:'flex-start', alignItems:'center', flexDirection:'column', height:'650px', overflowY: 'scroll', overflowX:'hidden'}}>
                            { searchedUsers.map((x, i) => {
                                return (
                                    <UserItem
                                        linkToProfile={linkToProfile}
                                        user={x}
                                        index={i}
                                        searchedUsers={searchedUsers}
                                        setSearchedUsers={setSearchedUsers}
                                    />
                                )
                            })}
                        </div>
                    </Col>
                </Loader>
            </Row>
        </DashboardItem>
    )
}


const UserItem = ({linkToProfile, user, index, searchedUsers, setSearchedUsers}: {linkToProfile:(x:string)=>void, user: IUserResponse, index: number, searchedUsers: IUserResponse[], setSearchedUsers:(v:IUserResponse[])=>void}) => {

    const supabase = useSupabaseClientStore(state=> state.supabaseClient)
    const loggedInUser = useUserStore(state=>state.user)

    const popToast = useToastStore(state=>state.pop)

    const [hovering, setHovering] = useState(false)

    return (
        <Row 
            onMouseEnter={() => setHovering(true)} 
            onMouseLeave={() => setHovering(false)} 
            style={{padding:'5px', width:'100%', borderRadius:'5px', cursor:'pointer', marginTop:'15px', backgroundColor: hovering ? colors.cardlight : 'transparent'}} 
            onClick={() => {linkToProfile(user.id)}} 
            key={`followlist${index}`}
        >
            <Col xs='2'>
                <div style={{height:'50px', width:'50px', backgroundColor: colors.borderLight, overflow:'hidden', borderRadius:'10px'}}>
                    <img src={user?.profileimage ?? defaultImage} height={'100%'}/>
                </div>
            </Col>
            <Col xs='7'>
                <Row>
                    <Col>
                        <Text  color={colors.backgroundDark} bold text={`${user.name ?? ''}`}/>
                    </Col>
                </Row>
                <Row>
                    <Col style={{whiteSpace:'initial'}}>
                        <Text color={colors.backgroundDark} text={user.bio ?? ''}/>
                    </Col>
                </Row>
            </Col>
            <Col xs='3'>
                <Button 
                    onClick={async (e) => {
                        e.stopPropagation()
                        if(!user || !loggedInUser) {
                            popToast('Oops, something went wrong', 'Error')
                            return
                        }
                        if(user.following) {
                            await supabase.from('Follow').delete().eq('user_from', loggedInUser.id).eq('user_to', user.id).then(res => {
                                setSearchedUsers(searchedUsers.map(y => {
                                    if(user.id === y.id) {
                                        return {
                                            ...y,
                                            following: false
                                        }
                                    }
                                    return y
                                }))
                            })
                            popToast('Unfollowed User - ' + user.name, 'Unfollowed')
                        }else {
                            await supabase.from('Follow').insert({
                                user_from: loggedInUser.id,
                                user_to: user.id,  
                            }).then(res => {
                                setSearchedUsers(searchedUsers.map(y => {
                                    if(user.id === y.id) {
                                        return {
                                            ...y,
                                            following: true
                                        }
                                    }
                                    return y
                                }))
                            })
                            popToast('Followed User - ' + user.name, 'Followed')
                        }
                    }}
                    style={{fontSize:'14px',border:'none', backgroundColor:colors.darkBlue, width:'100%', display:'flex', justifyContent:'center'}}
                >
                    {user.following ? 'Unfollow' : 'Follow'}
                </Button>
            </Col>
        </Row>
    )
}


export default Users