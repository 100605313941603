import React from 'react'
import DashboardItem from './DashboardItem'
import { Col, Image, Row } from 'react-bootstrap'
import Text from '../../Components/Shared/Text'

const bellicon = require('../../Assets/iconnotificationgrey.png')

const Notifications = () => {
    return (
        <DashboardItem height={280}>
            <Row style={{width:'100%'}}>
                <Col style={{display:'flex', justifyContent:'flex-start'}}>
                    <Text align='left'  bold text={'Notifications'}/>
                </Col>
                <Col style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                    <Image width={22} height={22} src={bellicon}/>
                </Col>
            </Row>
            <Row style={{marginTop:'50px'}}>
                <Col style={{display:'flex', justifyContent:'center'}}>
                    <Text text={'(Coming Soon)'}/>
                </Col>
            </Row>
        </DashboardItem>
    )
}

export default Notifications