import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Dashboard } from '../../Pages/Dashboard/Dashboard'
import { Profile } from '../../Pages/Profile/Profile'
import { ProfileId } from '../../Pages/UserProfile/UserProfile'
import Laptimes from '../../Pages/Laptimes/Main'
import { Discussion } from '../../Pages/Discussion'
import { Events } from '../../Pages/Events'
import { Teams } from '../../Pages/Teams'
import { Medals } from '../../Pages/Medals'
import useTrackListStore from '../../stores/TracklistStore'
import { useSupabaseClientStore } from '../../stores/supabaseClientStore'

export const paths = {
    dashboard: '/',
    profile: '/profile',
    userProfile: '/userprofile/:id',
    laptimes: '/laptimes',
    discussion: '/discussion',
    events: '/events',
    teams: '/teams',
    medals: '/medals'
}

const router = createBrowserRouter([
    {
        path: paths.dashboard,
        element: <Dashboard/>,
        loader: () => {return <Spinner/>},
    },
    {
        path: paths.profile,
        element: <Profile/>,
        loader: () => {return <Spinner/>}
    },
    {
        path: paths.userProfile,
        element: <ProfileId/>,
        loader: () => {return <Spinner/>}
    },
    {
        path: paths.laptimes,
        element: <Laptimes/>,
        loader: () => {return <Spinner/>}
    },
    {
        path: paths.discussion,
        element: <Discussion/>,
        loader: () => {return <Spinner/>}
    },
    {
        path: paths.events,
        element: <Events/>,
        loader: () => {return <Spinner/>}
    },
    {
        path: paths.teams,
        element: <Teams/>,
        loader: () => {return <Spinner/>}
    },
    {
        path: paths.medals,
        element: <Medals/>,
        loader: () => {return <Spinner/>}
    }
])

export const Router = () => {
    
    const supabase = useSupabaseClientStore(state=>state.supabaseClient)
    const load = useTrackListStore(state=>state.load)

    useEffect(() => {
        load(supabase)
    }, [])

    return (<RouterProvider router={router}/>)
}