import React, { createContext, useContext, useEffect, useState } from 'react'
import { Page } from '../../Components/Shared/Page'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import RecentTimes from './RecentTimes'
import VerifyTimes from './VerifyLaptimes'
import { useSupabaseClientStore } from '../../stores/supabaseClientStore'
import { useUserStore } from '../../stores/UserStore'
import { IUserResponse } from '../../Models'
import Loader from '../../Components/Shared/Loader'
import Leaderboards from './Leaderboards/Leaderboards'
import YourTimes from './YourLaptimes/YourLaptimes'

const useLaptimesPageData = () => {

    const supabase = useSupabaseClientStore(state=>state.supabaseClient)
    const user = useUserStore(state=>state.user)

    const [followingList, setFollowingList] = useState<IUserResponse[]>([])
    const [loadingData, setLoadingData] = useState(false)

    const [selectedTab, setSelectedTab] = useState('recentTimes')

    const loadData = async () => {
        setLoadingData(true)
        if(user){
            const followingIds = (await supabase.from('Follow').select('*').eq('user_from', user.id)).data?.map(x => x.user_to) ?? []
            let users = (await supabase.from('Profiles').select('*').in('id', [...followingIds, user.id])).data
            if(users) {
                setFollowingList(users?.map(x => {
                    return {
                        ...x,
                        label: `${x.name} "${x.nickname}"` ?? '',
                        value: `${x.name} "${x.nickname}"` ?? '',
                    }
                }))
            }
        }
        setLoadingData(false)
    }

    useEffect(() => {
        loadData()
    }, [])

    return {
        followingList,
        loadingData,
        selectedTab, 
        setSelectedTab
    }
}

interface ILaptimesPageStore {
    followingList: IUserResponse[]
    loadingData: boolean,
    selectedTab: string,
    setSelectedTab:(v: string)=>void
}
export const LaptimesPageStore = createContext<ILaptimesPageStore>({} as ILaptimesPageStore)

const LaptimesPage = () => {

    const pageData = useLaptimesPageData()

    return (
        <Page>
            <LaptimesPageStore.Provider value={pageData}>
                <Loader loading={pageData.loadingData}>
                    <PageTabs/>
                </Loader>
            </LaptimesPageStore.Provider>
        </Page>
    )
}

export default LaptimesPage

const PageTabs = () => {

    const pageData = useContext(LaptimesPageStore)

    return (
        <Row>
            <Col xs={11}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={pageData.selectedTab}
                    onSelect={(k) => {pageData.setSelectedTab(k ?? 'recentTimes')}}
                    className="mb-3"
                >
                    <Tab eventKey="recentTimes" title="Recent Times">
                        <RecentTimes/>
                    </Tab>
                    <Tab eventKey="yourtimes" title="Your Laptimes">
                        <YourTimes/>
                    </Tab>
                    <Tab eventKey="verifyTimes" title="Verify Laptimes">
                        <VerifyTimes/>
                    </Tab>
                    <Tab eventKey="leaderboards" title="Leaderboards">
                        <Leaderboards/>
                    </Tab>
                </Tabs>
            </Col>
            <Col xs={1}/>
        </Row>
    )
}