import React, { useState } from 'react'
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore'
import { useUserStore } from '../../../stores/UserStore'
import { useToastStore } from '../../../stores/PopToastStore'
import { Button, Col, Row } from 'react-bootstrap'
import Loader from '../../../Components/Shared/Loader'
import Text from '../../../Components/Shared/Text'
import { colors } from '../../../styles/colors'

const UpdateDetails = ({loading, setLoading, reloadUser}: {loading:boolean, setLoading:(v:boolean)=>void, reloadUser:()=>void}) => {

    const supabase = useSupabaseClientStore(state => state.supabaseClient)
    const user = useUserStore(state => state.user)

    const [name, setName] = useState(user?.name)
    const [nickName, setNickName] = useState(user?.nickname)
    const [bio, setBio] = useState(user?.bio)

    const popToast = useToastStore(state=>state.pop)

    const saveDetails = async () => {
        setLoading(true)
        if(user){
            await supabase.from('Profiles').update({
                name: name,
                nickname: nickName,
                bio: bio,
            }).eq('id', user.id)
            await reloadUser()
            popToast('Details Updated', 'Updated')
        }
        setLoading(false)
    }

    return (
        <Row>
            <Col style={{marginTop:'40px', height:'250px', backgroundColor:colors.white, borderRadius:'20px', padding:'20px',  boxShadow: '1px 2px 9px #F4AAB9'}}>
                <Row>
                    <Loader loading={loading}>
                         <Col>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Text color={colors.textLight} bold text={'Name'}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:'20px'}}>
                                        <Col>
                                            <div style={{backgroundColor:colors.white, width:'100%', height:'40px', display:'flex', alignItems:'center', border: `solid ${colors.borderLight} 1px`, borderRadius:'5px'}}>
                                                <input value={name ?? ''} onChange={(e) => setName(e.target.value)} type='text' style={{width:'100%', border:'none', outline:'none'}}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Text color={colors.textLight} bold text={'Nick Name'}/>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:'20px'}}>
                                        <Col xs='8'>
                                            <div style={{backgroundColor:colors.white, width:'100%', height:'40px', display:'flex', alignItems:'center', border: `solid ${colors.borderLight} 1px`, borderRadius:'5px'}}>
                                                <input value={nickName ?? ''} onChange={(e) => setNickName(e.target.value)} type='text' style={{width:'100%', border:'none', outline:'none'}}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{marginTop:'20px'}}>
                                <Col>
                                    <Text color={colors.textLight} bold text={'Bio'}/>
                                </Col>
                            </Row>
                            <Row style={{marginTop:'20px'}}>
                                <Col xs='10'>
                                    <div style={{backgroundColor:colors.white, width:'100%', height:'40px', display:'flex', alignItems:'center', border: `solid ${colors.borderLight} 1px`, borderRadius:'5px'}}>
                                        <input value={bio ?? ''} onChange={(e) => setBio(e.target.value)} type='text' style={{width:'100%', border:'none', outline:'none'}}/>
                                    </div>
                                </Col>
                                <Col xs='2' style={{display:'flex', justifyContent: 'flex-end'}}>
                                    <Button onClick={() => saveDetails()} style={{backgroundColor: colors.darkBlue, border:'none'}}>
                                        Save Changes
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Loader>
                </Row>
            </Col>
        </Row>
            
    )
}

export default UpdateDetails