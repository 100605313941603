import { create } from "zustand";
  
export interface IUser {
    email: string,
    userId: string,
    bio: string|null
    coverimage: string|null
    created_at: string
    name: string|null
    id: string
    localtrack: number|null
    nickname: string|null
    profileimage: string|null
    team1: number|null
    team2: number|null
    team3: number|null
    usertype: number|null
    profileImageUrl?: string
    coverImageUrl?: string
}

interface IUserStore {
    user: IUser|null
    setUser: (v: IUser|null) => void
}

export const useUserStore = create<IUserStore>()((set) => ({
    user: null,
    setUser:(u: IUser|null)=> set({user: u}),
}));
