import React, { ChangeEvent, useState } from 'react'
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore'
import { useUserStore } from '../../../stores/UserStore'
import { useToastStore } from '../../../stores/PopToastStore'
import { Button, Col, Row } from 'react-bootstrap'
import { colors } from '../../../styles/colors'
import Text from '../../../Components/Shared/Text'
import Loader from '../../../Components/Shared/Loader'

const UpdateProfilePicture = ({loading, setLoading, reloadUser}: {loading:boolean, setLoading:(v:boolean)=>void, reloadUser:()=>void}) => {

    const supabase = useSupabaseClientStore(state => state.supabaseClient)
    const user = useUserStore(state => state.user)
    const [newProfilePic, setNewProfilePic] = useState<string|null>(null)
    const [newProfilePicFile, setNewProfilePicFile] = useState<File|null>(null)

    const popToast = useToastStore(state=>state.pop)

    const setProfilePicFile = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setNewProfilePicFile(file)
            const reader = new FileReader();
            reader.onload = () => {
                setNewProfilePic(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    

    const saveProfilePic = async () => {

        setLoading(true)
        if(newProfilePicFile) {
            if(newProfilePicFile.size > 2050000){
                popToast('File must be smaller than 2MB', 'File Too Large')
                setLoading(false)
                return
            }
            try{
                
                let uuid = crypto.randomUUID()
                await supabase.storage.from('profilepictures').upload(uuid, newProfilePicFile)
                if(user?.profileimage) {
                    await supabase.storage.from('profilepictures').remove([user?.profileimage])
                }
                if(user?.id){
                    await supabase.from('Profiles').update({
                        profileimage: uuid
                    }).eq('id', user.id)
                }
                popToast('Profile picture updated', 'Updated')
                reloadUser()
            }
            catch(e) {
                popToast('Upload Failed', 'Failed') 
            }
        }
        setLoading(false)
    }

    return (
        <Col xs='11' style={{backgroundColor:colors.white, height:'350px', borderRadius:'20px', padding:'20px',  boxShadow: '1px 2px 9px #F4AAB9'}}>
            <Row>
                <Col>
                    <Text bold color={colors.textLight} text={'Profile Picture'}/>
                </Col>
            </Row>
            <Loader loading={loading}>
                <>
                    <Row style={{display:'flex', marginTop:'20px', flexDirection:'row'}}>
                        <Col style={{display:'flex', justifyContent:'flex-start'}}>
                            <input type='file' onChange={setProfilePicFile}/>
                        </Col>
                        <Col style={{display:'flex', justifyContent:'flex-end'}}>
                            <Button onClick={()=> saveProfilePic()} style={{backgroundColor: colors.darkBlue, border:'none'}}>
                                Save Changes
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'10px'}}>
                        <Col>
                            { newProfilePic 
                                ? <div style={{height:'200px', borderRadius:'20px', backgroundImage: `url(${newProfilePic})`, width:'200px', backgroundSize:'cover'}}/>
                                : <div style={{height:'200px', borderRadius:'20px', backgroundImage: `url(${user?.profileImageUrl})`, width:'200px', backgroundSize:'cover'}}/>
                            }
                        </Col>
                    </Row>
                </>
            </Loader>
        </Col>
    )
}

export default UpdateProfilePicture
