import React, { ReactElement } from 'react'
import Text from './Text'
import { colors } from '../../styles/colors'
import * as Spinners from 'react-spinners'

const Loader = ({loading, withText = true, color = colors.textLight, children}: {loading:boolean, color?: string, withText?: boolean, children: ReactElement}) => {

    if(loading) return <div style={{
        width:'100%',
        height:'100%',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    }}>
        <Spinners.BeatLoader size={5} color={color}/>
        {withText && <div style={{marginLeft:'20px'}}><Text text={'Loading ...'} color={color}/></div>}
    </div>

    return <>{children}</>
}

export default Loader