import React, { ChangeEvent, useState } from 'react'
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore'
import { useUserStore } from '../../../stores/UserStore'
import { useToastStore } from '../../../stores/PopToastStore'
import { Button, Col, Row } from 'react-bootstrap'
import { colors } from '../../../styles/colors'
import Loader from '../../../Components/Shared/Loader'
import Text from '../../../Components/Shared/Text'

const UpdateHeaderImage = ({loading, setLoading, reloadUser}: {loading:boolean, setLoading:(v:boolean)=>void, reloadUser:()=>void}) => {
    
    const supabase = useSupabaseClientStore(state=>state.supabaseClient)
    const user = useUserStore(state=>state.user)
    const [newHeader, setNewHeader] = useState<string|null>(null)
    const [newHeaderFile, setNewHeaderFile] = useState<File|null>(null)

    const popToast = useToastStore(state=>state.pop)

    const setHeaderFile = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setNewHeaderFile(file)
            const reader = new FileReader();
            reader.onload = () => {
                setNewHeader(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const saveHeader = async () => {
        setLoading(true)
        if(newHeaderFile) {
            if(newHeaderFile.size > 2050000){
                popToast('File must be smaller than 2MB', 'File Too Large')
                setLoading(false)
                return
            }
            try{
                let uuid = crypto.randomUUID()
                let result = (await supabase.storage.from('coverpictures').upload(uuid, newHeaderFile))
                if(result.error) throw new Error()
                if(user?.coverimage) {
                    await supabase.storage.from('coverpictures').remove([user?.coverimage])
                }
                if(user?.id){
                    await supabase.from('Profiles').update({
                        coverimage: uuid
                    }).eq('id', user.id)
                } else throw new Error()
                popToast('Header Saved', 'Saved')
                reloadUser()
            }
            catch(e) {
                console.log(e)
                popToast('Update Failed', 'Failed')    
            }
        }
        setLoading(false)
    }

    return (
        <Col style={{backgroundColor:colors.white, height:'350px', borderRadius:'20px', padding:'20px',  boxShadow: '1px 2px 9px #F4AAB9'}}>
            <Loader loading={loading}>
                <>
                    <Row>
                        <Col>
                            <Text bold color={colors.textLight} text={'Header Image'}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'20px'}}>
                        <Col>
                            <Row>
                                <Col style={{display:'flex', justifyContent: 'flex-start'}}>
                                    <input type='file' onChange={setHeaderFile}/>
                                </Col>
                                <Col style={{display:'flex', justifyContent: 'flex-end'}}>
                                    <Button onClick={() => saveHeader()} style={{backgroundColor: colors.darkBlue, border:'none'}}>
                                        Save Changes
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'10px'}}>
                        <Col>
                            { newHeader 
                                ? <div style={{height:'200px', borderRadius:'20px', backgroundImage: `url(${newHeader})`, width:'100%', backgroundSize:'cover'}}/>
                                : <div style={{height:'200px', borderRadius:'20px', backgroundImage: `url(${user?.coverImageUrl})`, width:'100%', backgroundSize:'cover'}}/>
                            }
                        </Col>
                    </Row>
                </>
            </Loader>
        </Col>
    )
}

export default UpdateHeaderImage