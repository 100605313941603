import React from 'react'
import { Page } from '../Components/Shared/Page'

export const Discussion = () => {

    return (
        <Page>
            Discussion (coming soon)
        </Page>
    )
}