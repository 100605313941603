import { create } from "zustand"

export type TPage = 'Dashboard'|'My Profile'|'Laptimes'|'Discussion'|'Events'|'Teams'|'Medals'|null

interface IMenuStore {
    page: TPage,
    setPage: (v: TPage)=>void
}

export const useMenuStore = create<IMenuStore>()((set) => ({
    page: 'Dashboard',
    setPage:(page => set({page: page}))
}))