import { create } from "zustand"

export type TScreens = 'Mobile'|'Tablet'|'DesktopSmall'|'DesktopLarge'

interface IScreenStore {
    screen: TScreens,
    setScreen: (v: TScreens)=>void
}

export const useScreenStore = create<IScreenStore>()((set) => ({
    screen: 'DesktopLarge',
    setScreen:(screen => set({screen: screen}))
}))