import React from 'react'
import { IRankedLaptime, ITrackOption } from '../../../Models'
import { Row } from 'react-bootstrap'
import Loader from '../../../Components/Shared/Loader'
import Text from '../../../Components/Shared/Text'
import { colors } from '../../../styles/colors'

const YourRank = ({loading, trackFilter, timeScale, userRankLaptime}: 
    {loading: boolean, trackFilter: ITrackOption|null, timeScale: 'AllTime'|'ThisYear'|'ThisMonth', userRankLaptime: IRankedLaptime|null}) => {
    return (
        <Row style={{marginTop:'50px'}}>
            <Loader loading={loading}>
                <>
                    <Text align='left' bold text={`Your Rank`}/>
                    <Text align='left' text={`${trackFilter?.name ?? '(no track selected)'} / ${timeScale === 'AllTime' ? 'All Time' : timeScale === 'ThisYear' ? 'This Year' : 'This Month'}`}/>
                    <div style={{display:'flex'}}>
                        <div style={{width:'150px',border:`solid ${colors.borderLight} 1px`, padding:'5px'}}><Text align='left'  text={`Time: ${userRankLaptime?.time?.toString() ?? '0.000'}`}/></div>
                        <div style={{width:'150px',border:`solid ${colors.borderLight} 1px`, padding:'5px'}}><Text align='left'  text={`Date: ${userRankLaptime ? new Date(userRankLaptime?.dateSet ?? '').toLocaleDateString() : '00/00/0000'}`}/></div>
                        <div style={{width:'150px',border:`solid ${colors.borderLight} 1px`, padding:'5px'}}><Text align='left'  text={`Rank: ${userRankLaptime?.rank?.toString() ?? 0}/${userRankLaptime?.of?.toString() ?? 0}`}/></div>
                    </div>
                </>
            </Loader>
        </Row>
    )
}

export default YourRank