import React from 'react'
import DashboardItem from './DashboardItem'
import { Col, Image, Row } from 'react-bootstrap'
import Text from '../../Components/Shared/Text'

const trophyicon = require('../../Assets/icontrophy.png')

const Ranking = () => {
    return (
        <DashboardItem height={300}>
            <Row style={{width:'100%'}}>
                <Col style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-start'}}>
                    <Text align='left'  bold text={'Ranking'}/>
                </Col>
                <Col style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                    <Image width={35} height={35} src={trophyicon}/>
                </Col>
            </Row>
            <Row style={{marginTop:'20px'}}>
                <Col>
                    <Text italic bold  text={'(Coming Soon)'}/>
                </Col>
            </Row>
            <Row style={{marginTop:'10px'}}>
                <Col>
                    <Text  text={'Rankings will be determined using an as of yet undetermined reputation system. Reputation will be earned through “Medals”, “Verified Laptimes” and overall “Community Contribution”.'}/>
                </Col>
            </Row>
        </DashboardItem>
    )
}
export default Ranking