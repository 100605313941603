import React from "react";

const Text = ({
    size = 0.9,
    align = "center",
    text = "",
    bold = false,
    italic = false,
    color = "black",
    underline = false
}: {
    text?: string | null;
    color?: string;
    bold?: boolean;
    italic?: boolean;
    size?: number;
    align?: "left" | "right" | "center";
    underline?: boolean
}) => {
    return (
        <span
            style={{
                width: "100%",
                textAlign: align,
                fontSize: `${size}rem`,
                textDecoration: underline ? 'underline' : 'none',
                color: color,
                fontWeight: bold ? "bold" : "normal",
                fontStyle: italic ? "italic" : "none",
            }}
        >
            {text}
        </span>
    );
};
export default Text;
