import React, { useState } from 'react';
import { colors } from '../../../styles/colors';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TimesByTrack from './TimesByTrack';
import QuickestTimes from './YourQuickestTimes';

const LapTimesV2 = () => {

    const [tableMode, setTableMode] = useState<string>('fastest')

    return (
        <div style={{overflowY: 'scroll', height:'55vh', backgroundColor:colors.white, width:'100%', borderRadius:'20px', padding:'10px',  boxShadow: '1px 2px 9px #F4AAB9'}}>
            <Tabs
                id="controlled-tab-example"
                activeKey={tableMode}
                onSelect={(k) => setTableMode(k ?? 'fastest')}
                className="mb-3"
            >
                <Tab eventKey="fastest" title="Quickest Times">
                    <QuickestTimes/>
                </Tab>
                <Tab eventKey="bytrack" title="Times By Track">
                    <TimesByTrack/>
                </Tab>
            </Tabs>
        </  div>
    )
};

export default LapTimesV2