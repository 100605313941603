import React from 'react'
import { Button, Col } from 'react-bootstrap'
import { colors } from '../../../styles/colors'
import Text from '../../../Components/Shared/Text'

const SearchButton = ({lookup}:{lookup: Function}) => {
    return (
        <Col
            xs="2"
            style={{ display: "flex", alignItems: "flex-end" }}
        >
            <Button
                style={{
                    backgroundColor: colors.darkBlue,
                    width: "100%",
                    height:'40px',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    border:'none'
                }}
                onClick={() => lookup()}
            >
                <Text color={colors.white} text="Search" />
            </Button>
        </Col>
    )
}

export default SearchButton