import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Text from '../../../Components/Shared/Text'
import { colors } from '../../../styles/colors'

const TimeScaleFilter = ({timeScale, setTimeScale}: {timeScale: 'AllTime'|'ThisYear'|'ThisMonth', setTimeScale:(v:'AllTime'|'ThisYear'|'ThisMonth')=>void}) => {
    return (
        <Col xs="4">
            <Text text={"Time Scale"}  />
            <Row style={{width:'100%', backgroundColor: colors.borderLight, borderRadius:'5px', padding:'3px'}}>
                <Col style={{padding:'2px'}}>
                    <Button style={{
                            border:'none', height:'30px', width:'100%', fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center',
                            backgroundColor: timeScale === 'AllTime' ? colors.white : 'transparent',
                            color: colors.black
                        }}
                        onClick={() => setTimeScale('AllTime')}
                    >
                        All Time
                    </Button>
                </Col>
                <Col style={{padding:'2px'}}>
                    <Button 
                        style={{
                            border:'none', height:'30px', width:'100%', fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center',
                            backgroundColor: timeScale === 'ThisYear' ? colors.white : 'transparent',
                            color: colors.black
                        }}
                        onClick={() => setTimeScale('ThisYear')}
                    >
                        This Year
                    </Button>
                </Col>
                <Col style={{display:'flex', alignItems:'center', padding:'2px'}}>
                    <Button 
                        style={{
                            border:'none', height:'30px', width:'100%', fontSize:'12px', display:'flex', alignItems:'center', justifyContent:'center',
                            backgroundColor: timeScale === 'ThisMonth' ? colors.white : 'transparent',
                            color: colors.black
                        }}
                        onClick={() => setTimeScale('ThisMonth')}
                    >
                        This Month
                    </Button>
                </Col>
            </Row>
        </Col>
    )
}

export default TimeScaleFilter