import { create } from "zustand"

export interface IToast {
    id: string,
    message: string,
    title: string
}

export interface IToastStore {
    data: IToast[],
    setData: (v: IToast[])=>void,
    pop: (message:string,title:string) => void
}

export const useToastStore = create<IToastStore>()((set, get) => ({
    data: [],
    setData:(data => {
        set({data: data})
    }),
    pop: (message:string, title:string) => {
        let id = crypto.randomUUID()
        let newData = [...get().data]
        newData.push({
            id: id,
            message: message,
            title: title
        })
        get().setData(newData)
        setTimeout(() => {
            get().setData(get().data.filter(x => x.id !== id))
        }, 4000)
    }
}))