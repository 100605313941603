import React from 'react'
import { useToastStore } from "../stores/PopToastStore"
import Toast from 'react-bootstrap/Toast';
import { ToastContainer as TC } from 'react-bootstrap';
import { colors } from '../styles/colors';

const ToastContainer = () => {

    const toast = useToastStore(state=>state.data)

    return (
        <TC position='bottom-end' style={{padding:'20px'}}>
            { toast.map(x => {
                    return <Toast style={{
                            backgroundColor: colors.backgroundDark
                        }}>
                            <Toast.Header style={{color: colors.white, backgroundColor: colors.backgroundDark, borderBottom: `solid ${colors.borderLight} 1px`}}>
                                <strong className="me-auto">{x.title}</strong>
                            </Toast.Header>
                            <Toast.Body style={{color: colors.white}}>{x.message}</Toast.Body>
                        </Toast>
                })
            }
        </TC>
    )
}

export default ToastContainer