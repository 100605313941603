import { Session, SupabaseClient } from "@supabase/supabase-js"
import { Database } from "../databaseTypes"
import { useSupabaseClientStore } from "../stores/supabaseClientStore"
import { useToastStore } from "../stores/PopToastStore"
import { useSessionStore } from "../stores/SessionStore"
import { useUserStore } from "../stores/UserStore"
import { useEffect, useState } from "react"

const useAuth = () => {

    const supabase: SupabaseClient<Database> = useSupabaseClientStore(state => state.supabaseClient)

    const popToast = useToastStore(state=>state.pop)

    const session = useSessionStore(state => state.session)
    const setSession = useSessionStore(state => state.setSession)
    
    const user = useUserStore(state => state.user)
    const setUser = useUserStore(state => state.setUser)

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const [mode, setMode] = useState<'Login'|'ForgottenPassword'|'Signup'>('Login')

    const handleLogin = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const result = await supabase.auth.signInWithOtp({
            email: email,
            options: {
                shouldCreateUser: false,
                emailRedirectTo: process.env.REACT_APP_AUTH_REDIRECT !== undefined ? process.env.REACT_APP_AUTH_REDIRECT : 'kartclan.co.uk/'
            }
        })
        if (result.error) {
            popToast(result.error.message, "Error")
        } else {
            popToast(`A login link has been emailed to you at ${email}`, "Login link emailed")
        }

        setLoading(false);
    };

    const prefetchUserImages = (profile: string|undefined|null, cover: string|undefined|null) => {
        if(profile){
            const img = new Image()
            img.src = profile
        }
        if(cover) {
            const img = new Image()
            img.src = cover
        }
    }

    const createAccount = async () => {
        setLoading(true);
        const result = await supabase.auth.signInWithOtp({
            email: email,
            options: {
                shouldCreateUser: true,
                emailRedirectTo: process.env.REACT_APP_AUTH_REDIRECT !== undefined ? process.env.REACT_APP_AUTH_REDIRECT : 'kartclan.co.uk/'
            }
        })
        if (result.error) {
            popToast(result.error.message, "Error")
            setLoading(false)
            throw new Error('Account not created')
        } else {
            popToast("Account created, check your email for a login link", "Account Created")
        }
        setLoading(false);
    }

    useEffect(() => {

        const handleSession = async (s: Session|null) => {
            if(s) {
                let user = {
                    email: s.user.user_metadata.email,
                    userId: s.user.user_metadata.sub
                }
                const res = ((await supabase.from('Profiles').select('*').eq('id', user.userId)).data ?? [])[0]
                if(res) {
                    if(res) {
                        let profImgUrl: string|null|undefined = null
                        let covImgUrl: string|null|undefined = null
                        if(res.profileimage) {
                            profImgUrl = (await supabase.storage.from('profilepictures').getPublicUrl(res.profileimage)).data.publicUrl
                        }
                        if(res.coverimage) {
                            covImgUrl = (await supabase.storage.from('coverpictures').getPublicUrl(res.coverimage)).data.publicUrl
                        }
                        let v = {
                            email: user.email,
                            userId: user.userId,
                            ...res,
                            profileImageUrl: profImgUrl ?? undefined,
                            coverImageUrl: covImgUrl ?? undefined
                        }
                        prefetchUserImages(profImgUrl, covImgUrl)
                        setUser(v)
                        setSession(s)
                    }
                }else {
                    popToast('User Account Not Found', 'Not Found')
                }
            } else {
                setSession(s)
            }
        }

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, s) => {
            handleSession(s)
        })

        return () => subscription.unsubscribe()
    }, [])

    return {
        session,
        user,
        email, setEmail,
        mode, setMode,
        loading,
        handleLogin,
        createAccount   
    }
}

export default useAuth