import React, { useEffect, useState } from 'react'
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore'
import { useUserStore } from '../../../stores/UserStore'
import useTrackListStore from '../../../stores/TracklistStore'
import { useToastStore } from '../../../stores/PopToastStore'
import { Button, Col, Row } from 'react-bootstrap'
import { colors } from '../../../styles/colors'
import Loader from '../../../Components/Shared/Loader'
import Text from '../../../Components/Shared/Text'
import Select from 'react-select'
import { ITrackOption } from '../../../Models'

const UpdateLocalTrack = ({loading, setLoading, reloadUser}: {loading:boolean, setLoading:(v:boolean)=>void, reloadUser:()=>void}) => {

    const supabase = useSupabaseClientStore(state => state.supabaseClient)
    const user = useUserStore(state => state.user)
    const trackList = useTrackListStore(state=>state.tracks)
    const [localTrack, setLocalTrack] = useState<ITrackOption|null>(null)

    const popToast = useToastStore(state=>state.pop)

    useEffect(() => {
        const load = async () => {
            setLoading(true)
            if(user?.localtrack) {
                let lt = trackList.find(x => x.id === user?.localtrack)
                if(lt) setLocalTrack({
                    ...lt,
                    label: lt.name ?? '',
                    value: lt.name ?? ''
                })
            }
            setLoading(false)
        }
        load()
    }, [])

    const saveLocaltrack = async () => {
        setLoading(true)
        if(user){
            await supabase.from('Profiles').update({
                localtrack: localTrack?.id
            }).eq('id', user.id)
            await reloadUser()
            popToast('Track Updated', 'Updated')
        }
        setLoading(false)
    }

    return (
        <Row style={{marginTop:'20px'}}>
            <Col style={{backgroundColor:colors.white, height:'120px', borderRadius:'20px', padding:'20px',  boxShadow: '1px 2px 9px #F4AAB9'}}>
                <Loader loading={loading}><>
                    <Row >
                        <Col>
                            <Text bold color={colors.textLight} text={'Local Track'}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'20px'}}>
                        <Col xs='10'>
                            <Select  isSearchable options={trackList} value={localTrack} onChange={(v) => {
                                let lt = trackList.find(x => x.value === v?.value)
                                if(lt) setLocalTrack(lt)
                            }}/>
                        </Col>
                        <Col xs='2' style={{display:'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => saveLocaltrack()} style={{backgroundColor: colors.darkBlue, border:'none'}}>
                                Save Changes
                            </Button>
                        </Col>
                    </Row>
                </></Loader>
            </Col>
        </Row>
    )
}

export default UpdateLocalTrack