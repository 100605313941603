import React from 'react'
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore'
import { useSessionStore } from '../../../stores/SessionStore'
import { useUserStore } from '../../../stores/UserStore'
import { useToastStore } from '../../../stores/PopToastStore'
import { Button, Col, Image, Row } from 'react-bootstrap'
import Text from '../../../Components/Shared/Text'
import { colors } from '../../../styles/colors'

const editIcon = require('../../../Assets/iconedit.png')

const FinishEditing = ({setEditMode}: {setEditMode: (v:boolean)=>void}) => {
    
    const supabase = useSupabaseClientStore(state=>state.supabaseClient)
    const setSession = useSessionStore(state=>state.setSession)
    const setUser = useUserStore(state=>state.setUser)
    const user = useUserStore(state=>state.user)

    const popToast = useToastStore(state=>state.pop)

    const deleteAccount = async () => {
        if(user){
            let res = window.confirm('Are you sure? Deleting your account is not reverse-able')

            if(res) {
                await supabase.from('Profiles').update({
                    triggerDeletion: 1
                }).eq('id', user.id)
                supabase.auth.signOut().then(res => {
                    setSession(null)
                    setUser(null)
                })
                popToast('Delete Successful', 'Deleted')
            }
        }
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col style={{padding:'0px'}}>
                        <Button onClick={() => setEditMode(false)} style={{backgroundColor:'transparent', border:'none', paddingLeft:'7px', paddingRight:'7px'}}>
                            <div style={{display:'flex'}}>
                                <Image src={editIcon} width={23} height={23}/>
                                <div style={{marginLeft:'10px'}}><Text bold underline text={'Finish Profile Edit'} color={colors.teal}/></div>
                            </div>
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col style={{display:'flex', justifyContent:'flex-end', padding:'0px'}}>
                <Button onClick={() => deleteAccount()} style={{width:'250px', border:'none', backgroundColor:colors.carddark, }}>
                    Delete Account
                </Button>
            </Col>
        </Row>
    )
}

export default FinishEditing