import React, { useContext, useEffect, useState } from "react";
import { colors } from "../../styles/colors";
import { Button, Col, Container, Row } from "react-bootstrap";
import Text from "../../Components/Shared/Text";
import Select from "react-select";
import useTrackListStore from "../../stores/TracklistStore";
import { ILaptime, ITrackOption, IUserResponse } from "../../Models";
import { LaptimesPageStore } from "./Main";
import { useSupabaseClientStore } from "../../stores/supabaseClientStore";
import Loader from "../../Components/Shared/Loader";
import Table, { IColumn } from "../../Components/Shared/Table";
import { useUserStore } from "../../stores/UserStore";
import {useToastStore} from '../../stores/PopToastStore'

const RecentTimes = () => {
    const user = useUserStore(state=>state.user)
    const supabase = useSupabaseClientStore((state) => state.supabaseClient);
    const trackList = useTrackListStore((state) => state.tracks);
    const pageCtx = useContext(LaptimesPageStore);

    const popToast = useToastStore(state=>state.pop)

    const [trackFilter, setTrackFilter] = useState<ITrackOption | null>(null);
    const [userFilter, setUserFilter] = useState<IUserResponse | null>(null);

    const [recentTimesResults, setRecentTimesResults] = useState<ILaptime[]>(
        []
    );

    const [loading, setLoading] = useState(false);

    const lookup = async () => {
        if(!user) return
        setLoading(true);
        // get 50 most recent laptimes of people you follow
        let laptimesResult: ILaptime[] = [];

        laptimesResult =
                (
                    await supabase
                        .from("Laptime")
                        .select("*")
                        .in(
                            "user",
                            pageCtx.followingList.map((x) => x.id)
                        )
                        .order("dateSet", { ascending: false })
                        .limit(25)
                ).data ?? [];

        if(trackFilter) laptimesResult = laptimesResult.filter(x => x.track === trackFilter.id)
        if(userFilter) laptimesResult = laptimesResult.filter(x => x.user === userFilter.id)

        const users =
            (
                await supabase
                    .from("Profiles")
                    .select("*")
                    .in(
                        "id",
                        laptimesResult.map((x) => x.user)
                    )
            ).data ?? [];

        // get verification counts of each laptime
        let verificationsResult =
            (
                await supabase
                    .from("LaptimeVerification")
                    .select("*")
                    .in(
                        "laptime",
                        laptimesResult.map((x) => x.id)
                    )
            ).data ?? [];

        // join verifications and laptimes onto laptimesResult
        laptimesResult = laptimesResult.map((x) => {
            const usr = users.find((y) => y.id === x.user);
            return {
                ...x,
                dateSet: x.dateSet
                    ? new Date(x.dateSet).toLocaleDateString()
                    : "",
                userName: `${usr?.name} "${usr?.nickname}"`,
                trackName: trackList.find((y) => y.id === x.track)?.name ?? "",
                verifications:
                    verificationsResult.filter((y) => y.laptime === x.id)
                        .length ?? 0,
                userHasVerified: verificationsResult.find(y => y.laptime === x.id && y.user === user.id) === undefined ? false : true
            };
        });

        setLoading(false);

        setRecentTimesResults(laptimesResult);
    };

    const handleVerify = async (laptimeId: number) => {
        if(!user) return
        // check if verification record exists
        let verified = (await supabase.from('LaptimeVerification').select('*').eq('user', user.id).eq('laptime', laptimeId)).data?.length ?? 0

        if(verified > 0) {
            popToast('Laptime Already Verified', 'Already Verified')
            return
        }

        // if no record - create record
        await supabase.from('LaptimeVerification').insert({
            user: user.id,
            laptime: laptimeId
        })

        // clear verification requested record
        await supabase.from('VerificationRequest').delete().eq('laptimeId', laptimeId)

        popToast('Laptime Verified, Thankyou', 'Verified')

        await lookup()
    }

    useEffect(() => {
        lookup();
    }, [pageCtx.selectedTab]);

    const columns: IColumn<ILaptime>[] = [
        {
            title: "User",
            accessor: "userName",
            width: 3,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.userName} />,
        },
        {
            title: "Track",
            accessor: "trackName",
            width: 2,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.trackName} />,
        },
        {
            title: "Laptime",
            accessor: "time",
            width: 2,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.time?.toString()} />,
        },
        {
            title: "Date Set",
            accessor: "dateSet",
            width: 2,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.dateSet} />,
        },
        {
            title: "Verifications",
            accessor: "verifications",
            width: 2,
            cell: (rowData: ILaptime) => {
                return <Text  align="left" text={rowData.verifications?.toString()} />;
            },
        },
        {
            title: "-",
            accessor: "verify",
            width: 1,
            cell: (rowData: ILaptime) =>{
                if(rowData.userHasVerified || rowData.user === user?.id) return <></>
                else return <Button style={{border:'none', width:'100%',height:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:colors.darkBlue, fontSize:14}} onClick={() => handleVerify(rowData.id)}>Verify Lap</Button>
            }
        }
    ];

    return (
        <div
            style={{
                marginTop: "20px",
                padding: "20px",
                height: "70vh",
                backgroundColor: colors.white,
                width: "100%",
                borderRadius: "20px",
                paddingTop: "10px",
                boxShadow: "1px 2px 9px #F4AAB9",
            }}
        >
            <Container fluid>
                <Row>
                    <Col xs="4">
                        <Text text={"Track Filter"}  />
                        <Select
                            isClearable
                            isSearchable
                            options={trackList}
                            value={trackFilter}
                            onChange={(v) => {
                                if (!v) {
                                    setTrackFilter(null);
                                    return;
                                }
                                let lt = trackList.find(
                                    (x) => x.value === v?.value
                                );
                                if (lt) setTrackFilter(lt);
                            }}
                        />
                    </Col>
                    <Col xs="4">
                        <Text text={"User Filter"}  />
                        <Select
                            isClearable
                            isSearchable
                            options={pageCtx.followingList}
                            value={userFilter}
                            onChange={(v) => {
                                if (!v) {
                                    setUserFilter(null);
                                    return;
                                }
                                let usr = pageCtx.followingList.find(
                                    (x) => x.value === v?.value
                                );
                                console.log(v);
                                if (usr) setUserFilter(usr);
                            }}
                        />
                    </Col>
                    <Col
                        xs="2"
                        style={{ display: "flex", alignItems: "flex-end" }}
                    >
                        <Button
                            style={{
                                backgroundColor: colors.darkBlue,
                                width: "100%",
                                border:'none'
                            }}
                            onClick={() => lookup()}
                        >
                            <Text color={colors.white} text="Search" />
                        </Button>
                    </Col>
                    <Col xs="2" />
                </Row>
                <Row style={{ marginTop: "30px", height: "55vh", overflowY:'scroll' }}>
                    <Loader loading={loading}>
                        <Table columns={columns} data={recentTimesResults} />
                    </Loader>
                </Row>
            </Container>
        </div>
    );
};

export default RecentTimes;