export const colors = {
    black: '#002A2E',
    backgroundLight:'#F8F8F8',
    backgroundDark: '#16161E',
    carddark: '#1A1B1F',
    cardDark2: '#002A2E',
    cardlight:'#EAEAEA',
    white: '#ffffff',
    textLight:'#8D8C8A',
    brightblue: '#0D82EC',
    borderGrey: '#7C7C7C',
    borderLight: '#DCDCDC',
    darkBlue: '#24598A',
    teal: '#399CAA',
    error: '#af342b',
    red: '#7E3333'
}