import React from 'react'
import { Col } from 'react-bootstrap'
import Text from '../../../Components/Shared/Text'
import { colors } from '../../../styles/colors'

const RankFilter = ({fromRankFilter, setFromRankFilter}: {fromRankFilter: number, setFromRankFilter:(v:number)=>void}) => {
    return (
        <Col xs="3">
            <Text text={"From Rank"}  />
            <div style={{backgroundColor:colors.white, width:'100%', height:'40px', display:'flex', alignItems:'center', border: `solid ${colors.borderLight} 1px`, borderRadius:'5px'}}>
                <input
                    style={{width:'100%', border:'none', outline:'none'}}
                    type='text'
                    value={fromRankFilter}
                    onChange={(e) => {
                        let inputVal: string|number = e.target.value
                        if(inputVal === '') inputVal = 0
                        const v: number = Number(inputVal) 
                        if(!isNaN(v)) {
                            setFromRankFilter(v)
                        }
                    }}
                />
            </div>
        </Col>
    )
}

export default RankFilter