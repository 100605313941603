import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { colors } from '../../../styles/colors';
import Text from '../../../Components/Shared/Text';
import Select from 'react-select'
import useTrackListStore from '../../../stores/TracklistStore';
import { ILaptime, ITrackOption } from '../../../Models';
import Loader from '../../../Components/Shared/Loader';
import Table, { IColumn } from '../../../Components/Shared/Table';
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore';

const DataTable = ({lookup, lapTimesResults, loading, verificationRequests, trackFilter, setTrackFilter}:{lookup: Function, lapTimesResults: ILaptime[], loading: boolean, verificationRequests: number[], trackFilter: ITrackOption|null, setTrackFilter:(v:ITrackOption|null)=>void}) => {
    
    const supabase = useSupabaseClientStore(state=>state.supabaseClient)
    const trackList = useTrackListStore((state) => state.tracks);

    const handleRequestVerification = async (laptimeId: number) => {
        await supabase.from('VerificationRequest').insert({laptimeId: laptimeId})
        await lookup()
    }

    const handleDeleteLaptime = async (laptimeId: number) => {
        await (supabase.from('Laptime').delete().eq('id', laptimeId))
        lookup()
    }

    const columns: IColumn<ILaptime>[] = [
        {
            title: "Laptime",
            accessor: "time",
            width: 2,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.time?.toString()} />,
        },
        {
            title: "Date Set",
            accessor: "dateSet",
            width: 2,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.dateSet} />,
        },
        {
            title: "Verifies",
            accessor: "verifications",
            width: 2,
            cell: (rowData: ILaptime) => {
                return <Text  align="left" text={rowData.verifications?.toString()} />;
            },
        },
        {
            title: "-",
            accessor: "requestVerify",
            width: 3,
            cell: (rowData: ILaptime) => {
                if(!verificationRequests.includes(rowData.id)) return (
                    <Button style={{border:'none', width:'100%',height:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:colors.darkBlue, fontSize:14}} onClick={() => handleRequestVerification(rowData.id)}>Request Verification</Button>
                )
                return <></>
            }
        },
        {
            title: "-",
            accessor: "delete",
            width: 3,
            cell: (rowData: ILaptime) => <Button style={{border:'none', width:'100%',height:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:colors.darkBlue, fontSize:14}} onClick={() => handleDeleteLaptime(rowData.id)}>Delete</Button>,
        },
    ];

    return (
        <Col xs='8'>
            <div
                style={{
                    marginTop: "20px",
                    padding: "20px",
                    height: "70vh",
                    backgroundColor: colors.white,
                    width: "100%",
                    borderRadius: "20px",
                    paddingTop: "10px",
                    boxShadow: "1px 2px 9px #F4AAB9",
                }}
            >
                <Row>
                    <Col xs="4">
                        <Text text={"Track Filter"} />
                        <Select
                            isSearchable
                            options={trackList}
                            value={trackFilter}
                            onChange={(v) => {
                                if (!v) {
                                    setTrackFilter(null);
                                    return;
                                }
                                let lt = trackList.find(
                                    (x) => x.value === v?.value
                                );
                                if (lt) setTrackFilter(lt);
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Loader loading={loading}>
                        <Table columns={columns} data={lapTimesResults} />
                    </Loader>
                </Row>
            </div>
        </Col>
    )
}

export default DataTable