import React, { useEffect, useState } from 'react'
import { ILaptime } from '../../../Models';
import Loader from '../../../Components/Shared/Loader';
import Table from './Table';
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore';
import { useUserLookupStore } from '../../../stores/UserLookupStore';
import useTrackListStore from '../../../stores/TracklistStore';

const QuickestTimes = () => {

    const supabase = useSupabaseClientStore(state=>state.supabaseClient)
    const user = useUserLookupStore(state => state.user)
    const [loading, setLoading] = useState(false)
    const [times, setTimes] = useState<ILaptime[]>([])
    const tracks = useTrackListStore(state=>state.tracks)


    useEffect(() => {
        loadData()
    }, [])

    const loadData = async() => {
        const getLaptimes = async() => {
            if(user) {
                const res = (await supabase.from('Laptime').select('*').eq('user', user.id)).data
                if(res) {
                    getVerifications(res)
                }
            }
        }
        const getVerifications = async(laptimes: ILaptime[]) => {
            if(user) {
                const res = await supabase.from('LaptimeVerification').select('*').eq('user', user.id)
                if(res.data) {
                    let modifiedLaptimes = laptimes
                    for(const laptime of modifiedLaptimes) {
                        laptime.verifications = res.data.filter(x => x.laptime === laptime.id).length
                        laptime.trackName = tracks?.find(x => x.id === laptime.track)?.name
                    }
                    setTimes(modifiedLaptimes)
                }
            }
        }
        setLoading(true)
        await getLaptimes()
        setLoading(false)
    }


    const columns = React.useMemo(
        () => [
            {
                Header: 'Track',
                accessor: 'trackName',
                width: '40%'
            },
            {
                Header: 'Lap Time',
                accessor: 'time',
                width: '20%'
            },
            {
                Header: 'Date Set',
                accessor: 'dateSet',
                width: '20%'
            },
            {
                Header: 'Verifications',
                accessor: 'verifications',
                width: '20%'
            },
            ],
        []
    );

    return (
        <Loader loading={loading}>
            <Table columns={columns} data={times} />
        </Loader>
    )
}

export default QuickestTimes