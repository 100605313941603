import { create } from "zustand";
import { ITrackOption } from "../Models";
import { Database } from "../databaseTypes";
import { SupabaseClient } from "@supabase/supabase-js";

export interface IUseTrackListStore {
    tracks: ITrackOption[],
    setTracks: (v:ITrackOption[]) => void
    load:(supabaseClient: SupabaseClient<Database>)=>{}
}

const useTrackListStore = create<IUseTrackListStore>()((set) => ({
    tracks: [],
    setTracks: (s: ITrackOption[]) => set({tracks: s}),
    load: async (supabaseClient: SupabaseClient<Database>) => {
        let trackListRes = (await supabaseClient.from('Track').select('*')).data
        if(trackListRes) {
            set({tracks: trackListRes.map(x => {
                return {
                    ...x,
                    label: x.name ?? '',
                    value: x.name ?? ''
                }
            })})
        }
    }
}))

export default useTrackListStore