import React from 'react'
import { Page } from '../../Components/Shared/Page'
import { Col, Row } from 'react-bootstrap'
import 'rc-segmented/assets/index.css'
import Feed from './Feed'
import Notifications from './Notifications'
import Users from './Users'
import Ranking from './Ranking'

export const Dashboard = () => {

    return (
        <Page>
            <Row>
                <Col xs='5'>
                    <Row>
                        <Col>
                            <Feed/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'20px'}}>
                        <Col>
                            <Notifications/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'20px'}}>
                        <Col>
                            <Ranking/>
                        </Col>
                    </Row>
                </Col>
                <Col xs='6'>
                    <Row>
                        <Col>
                            <Users/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Page>
    )
}