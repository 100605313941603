import React, { useContext, useEffect, useState } from 'react'
import { ILaptime, ITrackOption } from '../../../Models';
import useTrackListStore from '../../../stores/TracklistStore';
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore';
import { Container, Row } from 'react-bootstrap';
import { useUserStore } from '../../../stores/UserStore';
import { LaptimesPageStore } from '../Main';
import DataTable from '../YourLaptimes/DataTable';
import AddNewLaptime from '../YourLaptimes/AddNewLaptimes';

const YourTimes = () => {

    const supabase = useSupabaseClientStore((state) => state.supabaseClient);
    const user = useUserStore(state=>state.user)
    const pageCtx = useContext(LaptimesPageStore)
    const trackList = useTrackListStore((state) => state.tracks);

    const [trackFilter, setTrackFilter] = useState<ITrackOption | null>(null);

    const [lapTimesResults, setLapTimesResults] = useState<ILaptime[]>(
        []
    );

    const [verificationRequests, setVerificationReqests] = useState<number[]>([])

    const [loading, setLoading] = useState(false);

    const lookup = async () => {
        if(!trackFilter?.id || !user?.id) return
        setLoading(true)

        // lookup laptimes by track and logged in user
        let laptimeRes = (await supabase
            .from('Laptime')
            .select('*')
            .eq('track', trackFilter?.id)
            .eq('user', user?.id)).data ?? []
        
        // lookup verifications by laptime ids
        let verificationResults = (await supabase
            .from('LaptimeVerification')
            .select('*')
            .in('laptime', laptimeRes.map(x=>x.id))).data ?? []
        
        let verificationRequestsRes = (await supabase
            .from('VerificationRequest')
            .select('Laptime(*)')).data
            ?.map(x => x.Laptime)
            .filter(x => x?.user === user.id) ?? []

        let vRequests: number[] = []
        for(let i of verificationRequestsRes) if(i) vRequests.push(i.id)

        laptimeRes = laptimeRes.map(x => {
            return {
                ...x,
                trackName: trackList.find(y=>y.id === x.track),
                verifications: verificationResults?.filter(y=>y.laptime === x.id).length,
                dateSet: new Date(x.dateSet ?? '').toLocaleDateString()
            }
        })
        
        setVerificationReqests(vRequests)

        setLapTimesResults(laptimeRes)

        setLoading(false)
    }
    
    useEffect(() => {
        setTrackFilter(trackList[0])
    }, [])

    useEffect(() => {
        if(trackFilter) lookup();
    }, [trackFilter]);

    useEffect(() => {
        lookup()
    }, [pageCtx.selectedTab])

    return (
        <Container fluid>
            <Row>
                <DataTable
                    lookup={lookup}
                    lapTimesResults={lapTimesResults}
                    loading={loading}
                    verificationRequests={verificationRequests}
                    trackFilter={trackFilter}
                    setTrackFilter={setTrackFilter}
                />
                <AddNewLaptime trackFilter={trackFilter} lookup={lookup}/>
            </Row>
        </Container>
    )
}

export default YourTimes