import { SupabaseClient, createClient } from "@supabase/supabase-js"
import { create } from "zustand"
import { Database } from "../databaseTypes"

interface ISupabaseClientStore {
    supabaseClient: SupabaseClient<Database>
}

export const useSupabaseClientStore = create<ISupabaseClientStore>()((set) => ({
   supabaseClient: createClient<Database>(
        process.env.REACT_APP_SUPABASE_URL !== undefined ? process.env.REACT_APP_SUPABASE_URL : 'https://qtofvlucwasmdvixoqlr.supabase.co', 
        process.env.REACT_APP_SUPABASE_PUB !== undefined ? process.env.REACT_APP_SUPABASE_PUB : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF0b2Z2bHVjd2FzbWR2aXhvcWxyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTYzNjIyMDIsImV4cCI6MjAzMTkzODIwMn0.5ZRQQ1W4O6nOvs6PcN_eg0AxY8NIPzqAIOZDEN0bYng'
   )
}))