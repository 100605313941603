import React, { useState } from 'react'
import {Router} from './Router'
import { Col, Row } from 'react-bootstrap'
import './auth.css'
import Text from '../Shared/Text'
import { colors } from '../../styles/colors'
import useAuth from '../../hooks/useAuth'
import TextInput from '../Shared/TextInput'

const defaultImage = require('../../Assets/imgdefault.png')

const Auth = () => {

    const {
        session,
        user,
        email, setEmail,
        mode, setMode,
        loading,
        handleLogin,
        createAccount   
    } = useAuth()

    if (!session || !user) {
        return (
            <Row style={{marginTop:'0px'}}>
                <Col>
                    <Row style={{backgroundColor:'#002A2E', paddingTop:'10px', paddingBottom:'40px'}}>
                        <Col/>
                        <Col style={{display:'flex', justifyContent:'center'}}>
                            <img defaultValue={defaultImage} width={'150px'} src={require('../../Assets/logo.png')} alt={defaultImage}/>
                        </Col>
                        <Col/>
                    </Row>
                    <Row style={{display:'flex', justifyContent:'center', backgroundColor: '#002A2E', paddingBottom:'50px'}}>
                        <div style={{width:'500px', display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
                            { mode === 'Login'
                                && <Login
                                    handleLogin={handleLogin}
                                    email={email}
                                    setEmail={setEmail}
                                    loading={loading}
                                />
                            }
                            { mode === 'Signup'
                                && <Signup
                                    handleSignup={createAccount}
                                    email={email}
                                    setEmail={setEmail}
                                    loading={loading}
                                />
                            }
                        </div>
                        { mode === 'Login'
                            && <>
                                <div style={{marginTop:'10px', display:'flex', justifyContent:'center'}}>
                                    <div style={{cursor:'pointer'}} onClick={() => setMode('Signup')}>
                                        <Text underline color={colors.white} text={'Sign Up'}/>
                                    </div>
                                </div>
                            </>   
                        }
                        { mode === 'Signup'
                            && <>
                                <div style={{marginTop:'20px', display:'flex', justifyContent:'center'}}>
                                    <div style={{cursor:'pointer'}} onClick={() => setMode('Login')}>
                                        <Text underline color={colors.white} text={'Login'}/>
                                    </div>
                                </div>
                            </>   
                        }
                    </Row>
                </Col>
            </Row>
        )
    }
    else {
        return <Router />
    }
}

const Login = ({handleLogin, email, setEmail, loading}: {handleLogin:(e:any)=>Promise<void>, email:string, setEmail:(v:string)=>void, loading: boolean}) => {

    return (
        <>
            <Text size={1.3} text={'Login'} color={colors.white} bold/>
            <form onSubmit={handleLogin} style={{marginTop:'20px', width:'100%'}}>
                <TextInput
                    type='email'
                    value={email}
                    setValue={setEmail}
                    title='Email'
                    color='white'
                />
                <button type="submit" disabled={loading}>
                    {loading ? 'Loading...' : 'Login'}
                </button>
            </form>
            </>
    )
}


const Signup = ({handleSignup, email, setEmail, loading}: {handleSignup:()=>Promise<void>, email:string, setEmail:(v:string)=>void, loading: boolean}) => {

    const [didSignup, setDidSignup] = useState(false)

    if(didSignup) {
        return (
            <>
                <Text size={1.3} text={'Check your email'} color={colors.white} bold/>
                <Text color={colors.white} text={`You should have recieved a login link to your email at ${email}`}/>
            </>
        )
    }

    return (
        <>
            <Text size={1.3} text={'Sign - Up'} color={colors.white} bold/>
            <div style={{marginTop:'20px', width:'100%'}}>
                <TextInput
                    type='email'
                    value={email}
                    setValue={setEmail}
                    title='Email'
                    color='white'
                />
                <button onClick={ async () => {
                    try{
                        await handleSignup()
                        setDidSignup(true)
                    }catch(e){

                    }
                }} disabled={loading}>
                    {loading ? 'Loading...' : 'Sign Up'}
                </button>
            </div>
            </>
    )
}

export default Auth