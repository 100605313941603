import React from 'react'
import { Page } from '../Components/Shared/Page'

export const Teams = () => {

    return (
        <Page>
            Teams (coming soon)
        </Page>
    )
}