import React, { ReactNode } from "react";
import Text from "./Text";
import { colors } from "../../styles/colors";
import { Col, Row } from "react-bootstrap";

export interface IColumn<Tdata> {
    title: string;
    accessor: string; //property name
    width: number;
    cell: (rowData: Tdata) => ReactNode;
    data?: Tdata[];
}

const Table = <T,>({
    columns,
    data,
}: {
    columns: IColumn<T>[];
    data: any[];
}) => {
    return (
        <div style={{ width: "100%" }}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection:'column',
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Row style={{width:'100%'}}>
                    {columns.map((col) => {
                        //col.data = data.map((d) => d[col.accessor]);
                        return <Header col={col} />;
                    })}
                </Row>
                <Row style={{overflowY: 'scroll', width:'100%'}}>
                    {data.map((rowData) => {
                        //col.data = data.map((d) => d[col.accessor]);
                        return <R rowData={rowData} columns={columns}/>;
                    })}
                </Row>
            </div>
        </div>
    );
};

const Header = <T,>({col}: {col: IColumn<T>}) => {

    return (
        <Col xs={col.width} style={{padding:'5px', borderBottom:`solid ${colors.borderLight} 1px`}}>
            <Text bold  text={col.title}/>
        </Col>
    )
}

const R = <T,>({rowData, columns}: {rowData: T, columns: IColumn<T>[]}) => {

    return (
        <>{columns.map(C => {
            return <Col xs={C.width} style={{padding:'5px', borderBottom:`solid ${colors.borderLight} 1px`}}>
                {C.cell(rowData)}
            </Col>
        })}
        </>
    )
}

export default Table;
