import React from 'react'
import { useState } from "react"
import { useUserStore } from "../../../stores/UserStore"
import { useSupabaseClientStore } from "../../../stores/supabaseClientStore"
import { Col, Row } from "react-bootstrap";
import FinishEditing from './FinishEditing';
import UpdateHeaderImage from './UpdateHeaderImage';
import UpdateProfilePicture from './UpdateProfilePicture';
import UpdateLocalTrack from './UpdateLocalTrack';
import UpdateDetails from './UpdateDetails';

const EditMode = ({setEditMode}: {setEditMode: (v:boolean)=>void}) => {

    const user = useUserStore(state=>state.user)
    const setUser = useUserStore(state=>state.setUser)
    const supabase = useSupabaseClientStore(state => state.supabaseClient)

    const [loading, setLoading] = useState(false)

    const reloadUser = async () => {
        if(user) {
            let u = (await supabase.from('Profiles').select('*').eq('id', user.id)).data
            if(u && u[0]) {
                let pfUrl = (await supabase.storage.from('profilepictures').getPublicUrl(u[0].profileimage ?? '')).data.publicUrl
                let cvUrl = (await supabase.storage.from('coverpictures').getPublicUrl(u[0].coverimage ?? '')).data.publicUrl
                setUser({
                    email: user.email,
                    userId: user.userId,
                    bio: u[0].bio,
                    coverimage: u[0].coverimage,
                    created_at: u[0].created_at,
                    name: u[0].name,
                    id: u[0].id,
                    localtrack: u[0].localtrack,
                    nickname: u[0].nickname,
                    profileimage: u[0].profileimage,
                    team1: u[0].team1,
                    team2: u[0].team2,
                    team3: u[0].team3,
                    usertype: u[0].usertype,
                    profileImageUrl: pfUrl,
                    coverImageUrl: cvUrl
                })
            }
        }
    }

    return (
        <Row style={{padding:'10px'}}>
            <Col xs={11}>
                <FinishEditing setEditMode={setEditMode}/>
                
                <Row style={{marginTop:'20px', paddingBottom:'20px', display:'flex'}}>
                    <Col>
                        <Row>
                            <Col>
                                <Row>
                                    <UpdateHeaderImage
                                        reloadUser={reloadUser}
                                        loading={loading}
                                        setLoading={setLoading}
                                    />
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col xs='1'/>
                                    <UpdateProfilePicture
                                        reloadUser={reloadUser}
                                        loading={loading}
                                        setLoading={setLoading}
                                    />  
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <UpdateLocalTrack
                    reloadUser={reloadUser}
                    loading={loading}
                    setLoading={setLoading}
                />
                
                <UpdateDetails
                    reloadUser={reloadUser}
                    loading={loading}
                    setLoading={setLoading}
                />
            </Col>
            <Col xs={1}/>
        </Row>
    )
}

export default EditMode