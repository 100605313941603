import React, { useState } from 'react'
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore';
import { useUserStore } from '../../../stores/UserStore';
import useTrackListStore from '../../../stores/TracklistStore';
import { ITrackOption } from '../../../Models';
import { useToastStore } from '../../../stores/PopToastStore';
import { Button, Col, Row } from 'react-bootstrap';
import { colors } from '../../../styles/colors';
import Text from '../../../Components/Shared/Text';
import Select from 'react-select'
import ReactDatePicker from 'react-datepicker';

const AddNewLaptime = ({lookup, trackFilter}:{lookup: Function, trackFilter: ITrackOption|null}) => {

    const supabase = useSupabaseClientStore((state) => state.supabaseClient);
    const user = useUserStore(state=>state.user)
    const trackList = useTrackListStore((state) => state.tracks);

    const popToast = useToastStore(state=>state.pop)

    const [newLaptimeTrack, setNewLaptimeTrack] = useState<ITrackOption | null>(null)
    const [newLaptime, setNewLaptime] = useState<string | null>(null)
    const [dateSet, setDateSet] = useState<Date | null>(new Date())

    const handleLaptimeChange = (v: string) => {
        const n = Number(v)
        if(!isNaN(n)) {
            setNewLaptime(v)
        }
    }

    const handleCreateLaptime = async () => {
        if(!user) return
        if(!newLaptimeTrack) {
            popToast('No Track Selected', 'Missing Track')
            return
        }
        if(!newLaptime || newLaptime === '') {
            popToast('No laptime set', 'Missing Laptime')
            return
        }
        await supabase.from('Laptime').insert([{
            dateSet: dateSet?.toDateString(),
            kartType:1,
            time: Number(newLaptime),
            track: newLaptimeTrack.id,
            user: user.id
        }])

        if(trackFilter?.id === newLaptimeTrack.id) {
            lookup()
        }

        setNewLaptime('')
        setNewLaptimeTrack(null)
        setDateSet(new Date())

        popToast('Laptime Created', 'Created')
    }

    return (
        <Col xs='4'>
            <div
                style={{
                    marginTop: "20px",
                    padding: "20px",
                    height: "70vh",
                    backgroundColor: colors.white,
                    width: "100%",
                    borderRadius: "20px",
                    paddingTop: "10px",
                    boxShadow: "1px 2px 9px #F4AAB9",
                }}
            >
                <Row style={{marginTop:'20px', paddingBottom:'20px', marginBottom:'20px', borderBottom: `solid ${colors.borderLight} 2px`}}>
                    <Col style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Text align='center' text={'Add New Laptime'}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Text text='Select Track'/>
                        <Select
                            options={trackList}
                            value={newLaptimeTrack}
                            placeholder={'track'}
                            onChange={(v) => {
                                if(!v) setNewLaptimeTrack(null)
                                else {
                                    let val = trackList.find(x => x.id === v.id)
                                    if(val) setNewLaptimeTrack(val)
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col>
                        <Text text='Laptime'/>
                        <div style={{backgroundColor:colors.white, width:'100%', height:'40px', display:'flex', alignItems:'center', border: `solid ${colors.borderLight} 1px`, borderRadius:'5px'}}>
                            <input value={newLaptime ?? ''} onChange={(e) => handleLaptimeChange(e.target.value)} type='text' style={{width:'100%', border:'none', outline:'none'}}/>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                    <Col>
                        <Row>
                            <Col>
                                <Text text='Date Set'/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ReactDatePicker
                                    wrapperClassName='dp'
                                    className='date'
                                    value={dateSet?.toDateString() ?? new Date('').toDateString()}
                                    onChange={(v) => setDateSet(v)}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop:'20px'}}>
                            <Col>
                                <Button style={{border:'none', width:'100%', backgroundColor: colors.darkBlue}} onClick={() => handleCreateLaptime()}>
                                    Create
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

export default AddNewLaptime