import React from 'react'
import { colors } from '../../styles/colors'

const DashboardItem = ({children, height}:{children:React.ReactNode, height:number}) => {
    return (
        <div style={{
            display:'flex',
            flexDirection: 'column',
            height:`${height}px`,
            width:'100%',
            backgroundColor: colors.white,
            borderRadius:'20px',
            boxShadow: '1px 2px 9px #F4AAB9',
            padding:'20px'
        }}>
            {children}
        </div>
    )
}

export default DashboardItem