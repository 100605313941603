import { useEffect } from "react";
import { useScreenStore } from "../stores/ScreenStore";

const useScreenSize = () => {
    const setScreenType = useScreenStore(state=>state.setScreen)

    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth > 1800) setScreenType('DesktopLarge')
            if(window.innerWidth > 1200 && window.innerWidth <= 1800) setScreenType('DesktopSmall')
            if(window.innerWidth > 800 && window.innerWidth <= 1200) setScreenType('Tablet')
            if(window.innerWidth <= 800) setScreenType('Mobile')
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
}
export default useScreenSize