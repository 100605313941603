import { Session } from "@supabase/supabase-js"
import { create } from "zustand"

interface ISessionStore {
    session: Session|null
    setSession: (s: Session|null)=>void
}

export const useSessionStore = create<ISessionStore>()((set) => ({
    session: null,
    setSession: (s: Session|null) => set({session: s})
}))
