import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Text from '../../Components/Shared/Text'
import DashboardItem from './DashboardItem'

const Feed = () => {
    return (
        <DashboardItem height={280}>
            <Row style={{width:'100%'}}>
                <Col>
                    <Text bold text={'Feed'}/>
                </Col>
            </Row>
            <Row style={{marginTop:'50px'}}>
                <Col style={{display:'flex', justifyContent:'center'}}>
                    <Text text={'(Coming Soon)'}/>
                </Col>
            </Row>
        </DashboardItem>
    )
}

export default Feed