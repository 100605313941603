import React, { ReactNode, useEffect } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { colors } from '../../styles/colors'
import Text from './Text'
import { useSupabaseClientStore } from '../../stores/supabaseClientStore'
import { useUserStore } from '../../stores/UserStore'
import { TPage, useMenuStore } from '../../stores/MenuStore'
import { Link, useLocation } from 'react-router-dom'
import { useSessionStore } from '../../stores/SessionStore'

const defaultImage = require('../../Assets/imgdefault.png')

const iconHomeWhite = require('../../Assets/iconhomewhite.png')
const iconHomeGrey = require('../../Assets/iconhomegrey.png')
const iconProfileWhite = require('../../Assets/iconuserwhite.png')
const iconProfileGrey = require('../../Assets/iconusergrey.png')
const iconlaptimewhite = require('../../Assets/iconlaptimewhite.png')
const iconlaptimegrey = require('../../Assets/iconlaptimegrey.png')
const icondicussionwhite = require('../../Assets/icondiscussionwhite.png')
const icondiscussiongrey = require('../../Assets/icondiscussiongrey.png')
const iconeventswhite = require('../../Assets/iconeventswhite.png')
const iconeventsgrey = require('../../Assets/iconeventsgrey.png')
const iconteamswhite = require('../../Assets/iconteamswhite.png')
const iconteamsgrey = require('../../Assets/iconteamsgrey.png')
const iconmedalswhite = require('../../Assets/iconmedalswhite.png')
const iconmedalsgrey = require('../../Assets/iconmedalsgrey.png')

export const Page = ({children}: {children: ReactNode}) => {

    return (
        <div style={{display:'flex', width:'100vw', height:'100vh', overflowY:'scroll'}}>
            <div style={{minWidth:'250px', maxWidth:'250px', position:'absolute'}}>
                <Menu/>
            </div>
            <div style={{padding:'20px', width:'100%', marginLeft:'250px'}}>
                {children}
            </div>
        </div>
    )
}

type TPaths = '/'|'/profile'|'/laptimes'|'/discussion'|'/events'|'/teams'|'/medals'

interface IMenuItem {
    iconWhite:any,
    iconGrey:any,
    page: TPage,
    setPage:(v:TPage)=>void,
    path: TPaths
}

const Menu = () => {

    
    const supabase = useSupabaseClientStore(state => state.supabaseClient)
    const setSession = useSessionStore(state => state.setSession)
    
    const setPage = useMenuStore(state => state.setPage)

    const user = useUserStore(state => state.user)
    const setUser = useUserStore(state=>state.setUser)

    const loc = useLocation()

    useEffect(() =>{

        switch(loc.pathname){
            case '/':
                setPage('Dashboard')
                break
            case '/profile':
                setPage('My Profile')
                break
            case '/laptimes':
                setPage('Laptimes')
                break
            case '/discussion':
                setPage('Discussion')
                break
            case '/events':
                setPage('Events')
                break
            case '/teams':
                setPage('Teams')
                break
            case '/medals':
                setPage('Medals')
                break
            default:
                setPage(null)
        }

    }, [])
    
    const menuItems: IMenuItem[] = [
        {
            iconWhite:iconHomeWhite,
            iconGrey:iconHomeGrey,
            page: 'Dashboard',
            setPage:(v: TPage)=>{
                setPage('Dashboard')
            },
            path: '/'
        },
        {
            iconWhite:iconProfileWhite,
            iconGrey:iconProfileGrey,
            page: 'My Profile',
            setPage:(v: TPage)=>{
                setPage('My Profile')
            },
            path: '/profile'
        },
        {
            iconWhite:iconlaptimewhite,
            iconGrey:iconlaptimegrey,
            page: 'Laptimes',
            setPage:(v: TPage)=>{
                setPage('Laptimes')
            },
            path: '/laptimes'
        },
        {
            iconWhite:icondicussionwhite,
            iconGrey:icondiscussiongrey,
            page: 'Discussion',
            setPage:(v: TPage)=>{
                setPage('Discussion')
            },
            path: '/discussion'
        },
        {
            iconWhite:iconeventswhite,
            iconGrey:iconeventsgrey,
            page: 'Events',
            setPage:(v: TPage)=>{
                setPage('Events')
            },
            path: '/events'
        },
        {
            iconWhite:iconteamswhite,
            iconGrey:iconteamsgrey,
            page: 'Teams',
            setPage:(v: TPage)=>{
                setPage('Teams')
            },
            path: '/teams'
        },
        {
            iconWhite:iconmedalswhite,
            iconGrey:iconmedalsgrey,
            page: 'Medals',
            setPage:(v: TPage)=>{
                setPage('Medals')
            },
            path: '/medals'
        }
    ]

    return (
        <Container style={{backgroundColor:colors.backgroundDark, height:'100vh', width:'100%'}}>
            <Row style={{borderBottom: `solid ${colors.borderGrey} 2px`, paddingBottom:'10px'}}>
                <Col style={{display:'flex', justifyContent:'center'}}>
                    <Image width={'150px'} height={'auto'} src={require('../../Assets/logo.png')}/>
                </Col>
            </Row>
            <div style={{display:'flex', marginTop:'10px'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'60px', backgroundColor: colors.borderLight, width:'60px', overflow:'hidden', borderRadius:'10px'}}>
                    <img src={user?.profileImageUrl ?? defaultImage} height={'60px'}/>
                </div>
                <div style={{display:'flex', flexDirection:'column', marginLeft:'10px', justifyContent:'flex-start'}}>
                    <Text bold align='left' text={`${user?.name ?? ''}`} color={colors.white}/>
                    <Text align='left' italic text={`${user?.nickname ?? ''}`} color={colors.white}/>
                </div>
            </div>
            <Row style={{marginTop:'40px'}}>
                <Col>
                    { menuItems.map(x => {
                        return <MenuItem {...x}/>
                    })}
                </Col>
            </Row>
            <Row style={{marginTop:'50px', width:'100%'}}>
                <Col>
                    <Button style={{width:'100%', height:'35px', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:colors.darkBlue, border:'none'}} onClick={() => {
                        supabase.auth.signOut().then(res => {
                            setSession(null)
                            setUser(null)
                        })
                    }}>
                        <Text  color={colors.white} text='Log out'/>
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}

const MenuItem = ({
    iconWhite,
    iconGrey,
    page,
    setPage,
    path
}: IMenuItem) => {
    
    const _page = useMenuStore(state=>state.page)

    return (
        <Row style={{display:'flex', alignItems:'center', marginTop:'10px'}}>
            <Col xs="2"><Image width={'25px'} src={
                page === _page
                ? iconWhite
                : iconGrey
            }/></Col>
            <Col xs="10">
                <Link onClick={() => setPage(page)} to={path} style={{textDecoration:'none'}}>
                    <Text text={page} color={page === _page ? colors.white : colors.textLight}/>
                </Link>
            </Col>
        </Row>
    )
}
