import React from 'react'
import { Col, Row } from "react-bootstrap"
import { colors } from '../styles/colors'
import Text from './Shared/Text'

const MobilePlaceholder = () => {
    return (
        <Row style={{marginTop:'20px'}}>
            <Col></Col>
            <Col>
                <Row style={{backgroundColor:colors.black, border:'20px', paddingBottom:'40px'}}>
                    <Col>
                        <Row>
                            <Col style={{display:'flex', justifyContent:'center'}}>
                                <img width={'200px'} src={require('../Assets/logo.png')}/>
                            </Col>
                        </Row>
                        <Row style={{marginTop:'20px'}}>
                            <Text color={colors.white} size={1.2} bold text={'Welcome To Kart Clan'} />
                        </Row>
                        <Row style={{marginTop:'20px'}}>
                            <Text color={colors.white} size={1} text={`Mobile and Tablet views are not currently supported. Whilst we're still working on this please use the app on a laptop or desktop computer`} />
                        </Row>
                        <Row style={{marginTop:'20px'}}>
                            <Text color={colors.white} size={1} text={`Kart Clan is a work in progress and we appreciate your patience :)`} />
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col></Col>
        </Row>
    )
}
export default MobilePlaceholder