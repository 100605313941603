import React, { useEffect, useState } from 'react'
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore'
import { useUserStore } from '../../../stores/UserStore'
import { ILaptime, ITrackOption } from '../../../Models'
import Select from 'react-select'
import Loader from '../../../Components/Shared/Loader'
import Table from '../../../Components/Shared/Table'
import useTrackListStore from '../../../stores/TracklistStore'
import { IColumn } from '../../../Components/Shared/Table'
import Text from '../../../Components/Shared/Text'

const TimesByTrack = () => {

    const supabase = useSupabaseClientStore(state => state.supabaseClient)
    const user = useUserStore(state => state.user)
    const trackList = useTrackListStore(state=>state.tracks)
    const [selectedTrack, setSelectedTrack] = useState<ITrackOption|null>(null)
    const [times, setTimes] = useState<ILaptime[]>([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const load = async () => {
            setLoading(true)
            if(user?.localtrack) {
                let lt = trackList.find(x => x.id === user?.localtrack)
                if(lt) setSelectedTrack({
                    ...lt,
                    label: lt.name ?? '',
                    value: lt.name ?? ''
                })
            }
            setLoading(false)
        }
        load()
    }, [])

    useEffect(() => {
        const loadTimes = async () => {
            setLoading(true)

            if(user && selectedTrack) {
                const times: null|ILaptime[] = (await supabase.from('Laptime').select('*').eq('user', user.id).eq('track', selectedTrack?.id)).data ?? []
                const verificationsRes = await (await supabase.from('LaptimeVerification').select('*').in('laptime', times.map(x => x.id))).data ?? []
                let modifiedLaptimes = times
                for(const laptime of modifiedLaptimes) {
                    laptime.verifications = verificationsRes.filter(x => x.laptime === laptime.id).length
                    laptime.trackName = trackList?.find(x => x.id === laptime.track)?.name
                }
                setTimes(modifiedLaptimes)
                if (times) {
                    setTimes(times)
                }
            }

            setLoading(false)
        }
        loadTimes()
    }, [selectedTrack])

    const columns: IColumn<ILaptime>[] = [
        {
            title: "Lap Time",
            accessor: "time",
            width: 4,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.time?.toString()} />,
        },
        {
            title: "Date set",
            accessor: "dateSet",
            width: 4,
            cell: (rowData: ILaptime) => <Text  align="left" text={new Date(rowData.dateSet ?? '').toLocaleDateString()} />,
        },
        {
            title: "Verifications",
            accessor: "verifications",
            width: 4,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.verifications?.toString()} />,
        }
    ];
    
    return (
        <>
            <Select
                value={selectedTrack}
                onChange={(v) => {
                    let val = trackList.find(x => x.name === v?.name)
                    if(val) setSelectedTrack(val)
                }}
                options={trackList}
                placeholder='select track'
                isClearable
                isSearchable
            />
            <div style={{marginTop:'20px'}}/>
            <Loader loading={loading}>
                <Table columns={columns} data={times} />
            </Loader>
        </>
    )
}

export default TimesByTrack