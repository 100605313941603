import React, { useEffect, useState } from 'react'
import { Page } from '../../Components/Shared/Page'
import {useLocation} from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import UserInfo from './UserInfo'
import LocalTrack from './LocalTrack'
import { useSupabaseClientStore } from '../../stores/supabaseClientStore'
import Loader from '../../Components/Shared/Loader'
import { useUserLookupStore } from '../../stores/UserLookupStore'
import LapTimesV2 from './LapTimes/LapTimes'

const defaultImage = require('../../Assets/imgdefault.png')

export const ProfileId = () => {

    const supabase = useSupabaseClientStore(state=>state.supabaseClient)
    const user = useUserLookupStore(state => state.user)
    const setUser = useUserLookupStore(state => state.setUser)
    const location = useLocation()
    //const [user, setUser] = useState<IUser|null>(null)
    const [loading, setLoading] = useState(false)


    const prefetchUserImages = (profile: string|undefined|null, cover: string|undefined|null) => {
        if(profile){
            const img = new Image()
            img.src = profile
        }
        if(cover) {
            const img = new Image()
            img.src = cover
        }
    }


    useEffect(() => {
        const loadUser = async() => {
            setLoading(true)
            const userId = location.pathname.split('/')[location.pathname.split('/').length-1]
            if(!userId) return
            const res = await supabase.from('Profiles').select('*').eq('id', userId)
                if(res.data) {
                    if(res.data[0]) {
                        let profImgUrl: string|null|undefined = null
                        let covImgUrl: string|null|undefined = null
                        if(res.data[0].profileimage) {
                            profImgUrl = (await supabase.storage.from('profilepictures').getPublicUrl(res.data[0].profileimage)).data.publicUrl
                        }
                        if(res.data[0].coverimage) {
                            covImgUrl = (await supabase.storage.from('coverpictures').getPublicUrl(res.data[0].coverimage)).data.publicUrl
                        }
                        let v = {
                            ...res.data[0],
                            email: '',
                            userId: '',
                            profileImageUrl: profImgUrl ?? undefined,
                            coverImageUrl: covImgUrl ?? undefined
                        }
                        prefetchUserImages(profImgUrl, covImgUrl)
                        setUser(v)
                    }
                }
            setLoading(false)
        }
        loadUser()
    }, [])

    return (
        <Page>
            <Loader loading={loading}>
                <>
                    <Row style={{height:'30vh'}}>
                        <Col xs={11}>
                            <div style={{height:'30vh', overflow:'hidden', borderRadius:'20px'}}>
                                <img src={user?.coverImageUrl ?? defaultImage} width={'100%'}/>
                            </div>
                        </Col>
                        <Col xs={1}/>
                    </Row>
                    <Row style={{marginTop:'20px', padding:'10px'}}>
                        <Col xs={11} >
                            <Row>
                                <Col xs='4'>
                                    <Row>
                                        <Col xs='10'>
                                            <UserInfo/>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs='8'>
                                    <LapTimesV2/>
                                </Col>
                            </Row>
                        </Col>
                    <Col xs={1}/>
                    </Row>
                </>
            </Loader>
        </Page>
    )
}