import React, { useEffect, useState } from 'react'
import { ILaptime } from '../../../Models';
import Loader from '../../../Components/Shared/Loader';
import Table from '../../../Components/Shared/Table';
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore';
import { useUserStore } from '../../../stores/UserStore';
import useTrackListStore from '../../../stores/TracklistStore';
import Text from '../../../Components/Shared/Text';
import { IColumn } from '../../../Components/Shared/Table';

const QuickestTimes = () => {

    const supabase = useSupabaseClientStore(state=>state.supabaseClient)
    const user = useUserStore(state=>state.user)
    const [loading, setLoading] = useState(false)
    const [times, setTimes] = useState<ILaptime[]>([])
    const tracks = useTrackListStore(state=>state.tracks)


    useEffect(() => {
        loadData()
    }, [])

    const loadData = async() => {
        const getLaptimes = async() => {
            if(user) {
                const res = (await supabase.from('Laptime').select('*').eq('user', user.id)).data
                if(res) {
                    getVerifications(res)
                }
            }
        }
        const getVerifications = async(laptimes: ILaptime[]) => {
            if(user) {
                const res = await supabase.from('LaptimeVerification').select('*').in('laptime', laptimes.map(x => x.id))
                if(res.data) {
                    let modifiedLaptimes = laptimes
                    for(const laptime of modifiedLaptimes) {
                        laptime.verifications = res.data.filter(x => x.laptime === laptime.id).length
                        laptime.trackName = tracks?.find(x => x.id === laptime.track)?.name
                    }
                    setTimes(modifiedLaptimes)
                }
            }
        }
        setLoading(true)
        await getLaptimes()
        setLoading(false)
    }


    const columns: IColumn<ILaptime>[] = [
        {
            title: "Track",
            accessor: "trackName",
            width: 4,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.trackName} />,
        },
        {
            title: "Lap Time",
            accessor: "time",
            width: 2,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.time?.toString()} />,
        },
        {
            title: "Date set",
            accessor: "dateSet",
            width: 3,
            cell: (rowData: ILaptime) => <Text  align="left" text={new Date(rowData.dateSet ?? '').toLocaleDateString()} />,
        },
        {
            title: "Verifications",
            accessor: "verifications",
            width: 3,
            cell: (rowData: ILaptime) => <Text  align="left" text={rowData.verifications?.toString()} />,
        }
    ];

    return (
        <Loader loading={loading}>
            <Table columns={columns} data={times} />
        </Loader>
    )
}

export default QuickestTimes