import './index.css'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SupabaseAuth from './Components/Auth/Auth'
import { useScreenStore } from './stores/ScreenStore';
import useScreenSize from './hooks/useScreenSize';
import MobilePlaceholder from './Components/MobilePlaceholder';
import ToastContainer from './Components/ToastContainer';

const App = () => {

    useScreenSize()
    const screen = useScreenStore(state=>state.screen)
    
    if(process.env.REACT_APP_SUPABASE_URL !== undefined) console.log('environment => ','dev')

    if(screen === 'Tablet' || screen === 'Mobile') {
        return <MobilePlaceholder/>
    }

    return (
        <Container fluid className='p-0' style={{height:'100vh', width:'100vw', overflow:'hidden'}}>
            <ToastContainer/>
            <Row style={{borderColor:'blue', borderWidth:10}}>
                <Col>
                    <SupabaseAuth/>
                </Col>
            </Row>
        </Container>
    )
}
export default App