import React, { useEffect, useState } from 'react'
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore'
import { ILaptime, ITrackOption } from '../../../Models'
import Select from 'react-select'
import Loader from '../../../Components/Shared/Loader'
import Table from './Table'
import { useUserLookupStore } from '../../../stores/UserLookupStore'
import useTrackListStore from '../../../stores/TracklistStore'

const TimesByTrack = () => {

    const supabase = useSupabaseClientStore(state => state.supabaseClient)
    const user = useUserLookupStore(state => state.user)
    const trackList = useTrackListStore(state=>state.tracks)
    const [selectedTrack, setSelectedTrack] = useState<ITrackOption|null>(null)
    const [times, setTimes] = useState<ILaptime[]>([])

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const load = async () => {
            setLoading(true)
            if(user?.localtrack) {
                let lt = trackList.find(x => x.id === user?.localtrack)
                if(lt) setSelectedTrack({
                    ...lt,
                    label: lt.name ?? '',
                    value: lt.name ?? ''
                })
            }
            setLoading(false)
        }
        load()
    }, [])

    useEffect(() => {
        const loadTimes = async () => {
            setLoading(true)

            if(user && selectedTrack) {
                const times: null|ILaptime[] = (await supabase.from('Laptime').select('*').eq('user', user.id).eq('track', selectedTrack?.id)).data
                if (times) {
                    setTimes(times)
                }
            }

            setLoading(false)
        }
        loadTimes()
    }, [selectedTrack])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Lap Time',
                accessor: 'time',
                width: '20%'
            },
            {
                Header: 'Date Set',
                accessor: 'dateSet',
                width: '20%'
            },
            {
                Header: 'Verifications',
                accessor: 'verifications',
                width: '20%'
            },
            ],
        []
    );
    
    return (
        <>
            <Select
                value={selectedTrack}
                onChange={(v) => {
                    let val = trackList.find(x => x.name === v?.name)
                    if(val) setSelectedTrack(val)
                }}
                options={trackList}
                placeholder='select track'
                isClearable
                isSearchable
            />
            <div style={{marginTop:'20px'}}/>
            <Loader loading={loading}>
                <Table columns={columns} data={times} />
            </Loader>
        </>
    )
}

export default TimesByTrack