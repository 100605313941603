import React, { useEffect, useState } from 'react'
import { useUserStore } from '../../stores/UserStore'
import { useSupabaseClientStore } from '../../stores/supabaseClientStore'
import { Button, Col, Image, Row } from 'react-bootstrap'
import Text from '../../Components/Shared/Text'
import { colors } from '../../styles/colors'
import Loader from '../../Components/Shared/Loader'

const defaultImage = require('../../Assets/imgdefault.png')
const editIcon = require('../../Assets/iconedit.png')

const UserInfo = ({setEditMode}:{setEditMode:(v:boolean)=>void}) => {

    const user = useUserStore(state=>state.user)
    const supabase = useSupabaseClientStore(state=>state.supabaseClient)

    const [followerCount, setFollowerCount] = useState(0)
    const [followingCount, setFollowingCount] = useState(0)

    const [loadingFollowers, setLoadingFollowers] = useState(false)

    const [localTrack, setLocalTrack] = useState('')

    useEffect(() => {
        const loadFollowers = async () => {
            setLoadingFollowers(true)
            if(user) {
                let followers = (await supabase.from('Follow').select("*").eq('user_to', user.id)).data?.length ?? 0
                let following = (await supabase.from('Follow').select("*").eq('user_from', user.id)).data?.length ?? 0
                setFollowerCount(followers ?? 0)
                setFollowingCount(following ?? 0)
            }
            setLoadingFollowers(false)
        }
        const loadLocalTrack = async () => {
            if(user?.localtrack){
                let r = ((await supabase.from('Track').select('*').eq('id', user?.localtrack)).data ?? [])[0]
                if(r) setLocalTrack(r.name ?? '')
            }
        }
        loadFollowers()
        loadLocalTrack()
    }, [])

    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col style={{display:'flex', justifyContent:'flex-start'}}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'150px', backgroundColor: colors.borderLight, width:'190px', overflow:'hidden', borderRadius:'10px'}}>
                                <img src={user?.profileImageUrl ?? defaultImage} height={'100%'}/>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row style={{marginTop:'0px'}}>
                        <Col>
                            <Text size={1.3} bold text={`${user?.name ?? ''}`}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'5px'}}>
                        <Col>
                            <Text size={1.1} italic text={`${user?.nickname ?? ''}`}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop:'20px'}}>
                <Col>
                    <Text italic text={user?.bio ?? ''}/>
                </Col>
            </Row>
            <Row style={{marginTop:'20px', borderTop:`solid ${colors.borderLight} 1px`, borderBottom:`solid ${colors.borderLight} 1px`, paddingTop:'5px', paddingBottom:'5px'}}>
                <Col>
                    <Text text={'Local Track'}/>
                </Col>
                <Col style={{display:'flex', justifyContent:'flex-end'}}>
                    <Text bold align='right' text={localTrack}/>
                </Col>
            </Row>
            <Row style={{marginTop:'20px', height:'37px', borderRadius:'5px'}}>
                <Col xs='6' style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                    <Text align='left' size={1} text={'Followers'}/>
                </Col>
                <Col xs='6' style={{display:'flex', justifyContent:'flex-end', alignItems:'center',height:'100%', }}>
                    <Loader withText={false} loading={loadingFollowers}><Text align='right' size={1} bold text={followerCount.toString()}/></Loader>
                </Col>
            </Row>
            <Row style={{marginTop:'0px', height:'37px', borderRadius:'5px'}}>
                <Col xs='6' style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                    <Text align='left' size={1} text={'Following'}/>
                </Col>
                <Col xs='6' style={{display:'flex', justifyContent:'flex-end', alignItems:'center', height:'100%'}}>
                    <Loader withText={false} loading={loadingFollowers}><Text align='right' size={1} bold text={followingCount.toString()}/></Loader>
                </Col>
            </Row>
            <Row style={{marginTop:'20px', borderTop:`solid ${colors.borderLight} 1px`}}>
                <Col style={{marginTop:'20px',padding:'0px'}}>
                    <Button onClick={() => setEditMode(true)} style={{backgroundColor:'transparent', border:'none', paddingLeft:'7px', paddingRight:'7px'}}>
                        <div style={{display:'flex'}}>
                            <Image src={editIcon} width={23} height={23}/>
                            <div style={{marginLeft:'10px'}}><Text bold underline text={'Edit Profile'} color={colors.teal}/></div>
                        </div>
                    </Button>
                </Col>
            </Row>
        </>
    )
}


export default UserInfo