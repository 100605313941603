import React, { useEffect, useState } from 'react'
import { useSupabaseClientStore } from '../../stores/supabaseClientStore'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import Text from '../../Components/Shared/Text'
import { colors } from '../../styles/colors'
import Loader from '../../Components/Shared/Loader'
import { useUserLookupStore } from '../../stores/UserLookupStore'
import Modal from 'react-modal'
import { useUserStore } from '../../stores/UserStore'
import { useToastStore } from '../../stores/PopToastStore'

const defaultImage = require('../../Assets/imgdefault.png')
const warningIcon = require('../../Assets/iconwarning.png')

const UserInfo = () => {

    const user = useUserStore(state=>state.user)
    const userProfile = useUserLookupStore(state=>state.user)
    const supabase = useSupabaseClientStore(state=>state.supabaseClient)

    const popToast = useToastStore(state=>state.pop)

    const [followerCount, setFollowerCount] = useState(0)
    const [followingCount, setFollowingCount] = useState(0)

    const [loadingFollowers, setLoadingFollowers] = useState(false)

    const [showReportModal, setShowReportModal] = useState(false)
    const [reportText, setReportText] = useState('')

    const [localTrack, setLocalTrack] = useState('')

    useEffect(() => {
        const loadFollowers = async () => {
            setLoadingFollowers(true)
            if(userProfile) {
                let followers = (await supabase.from('Follow').select("*").eq('user_to', userProfile.id)).data?.length ?? 0
                let following = (await supabase.from('Follow').select("*").eq('user_from', userProfile.id)).data?.length ?? 0
                setFollowerCount(followers ?? 0)
                setFollowingCount(following ?? 0)
            }
            setLoadingFollowers(false)
        }
        const loadLocalTrack = async () => {
            if(userProfile?.localtrack){
                let r = ((await supabase.from('Track').select('*').eq('id', userProfile?.localtrack)).data ?? [])[0]
                if(r) setLocalTrack(r.name ?? '')
            }
        }
        loadLocalTrack()
        loadFollowers()
    }, [])

    const openReportUserModal = () => {
        setShowReportModal(true)
    }

    const reportUser = async () => {
        
        if(user && userProfile && reportText.length >= 5){
            await supabase.from('UserReport').insert({
               userId: userProfile.id,
               reportedBy: user.id,
               reason: reportText
            })
            setShowReportModal(false)
            popToast('Thank you for reporting', 'Reported')
        }else {
            popToast('Please provide a reason', 'Reason Required')
        }
        setReportText('')
    }

    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col style={{display:'flex', justifyContent:'flex-start'}}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'150px', backgroundColor: colors.borderLight, width:'190px', overflow:'hidden', borderRadius:'10px'}}>
                                <img src={userProfile?.profileImageUrl ?? defaultImage} height={'100%'}/>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row style={{marginTop:'0px'}}>
                        <Col>
                            <Text size={1.3} bold text={`${userProfile?.name ?? ''}`}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'5px'}}>
                        <Col>
                            <Text size={1.1} italic text={`${userProfile?.nickname ?? ''}`}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop:'20px'}}>
                <Col>
                    <Text italic text={userProfile?.bio ?? ''}/>
                </Col>
            </Row>
            <Row style={{marginTop:'20px', borderTop:`solid ${colors.borderLight} 1px`, borderBottom:`solid ${colors.borderLight} 1px`, paddingTop:'5px', paddingBottom:'5px'}}>
                <Col>
                    <Text text={'Local Track'}/>
                </Col>
                <Col style={{display:'flex', justifyContent:'flex-end'}}>
                    <Text bold align='right' text={localTrack}/>
                </Col>
            </Row>
            <Row style={{marginTop:'20px', height:'37px', borderRadius:'5px'}}>
                <Col xs='6' style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                    <Text align='left' size={1} text={'Followers'}/>
                </Col>
                <Col xs='6' style={{display:'flex', justifyContent:'flex-end', alignItems:'center',height:'100%', }}>
                    <Loader withText={false} loading={loadingFollowers}><Text align='right' size={1} bold text={followerCount.toString()}/></Loader>
                </Col>
            </Row>
            <Row style={{marginTop:'0px', height:'37px', borderRadius:'5px'}}>
                <Col xs='6' style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                    <Text align='left' size={1} text={'Following'}/>
                </Col>
                <Col xs='6' style={{display:'flex', justifyContent:'flex-end', alignItems:'center', height:'100%'}}>
                    <Loader withText={false} loading={loadingFollowers}><Text align='right' size={1} bold text={followingCount.toString()}/></Loader>
                </Col>
            </Row>
            <Row style={{borderTop:`solid ${colors.borderLight} 1px`, marginTop:'20px'}}>
                <div style={{marginTop:'20px', width:'150px', cursor:'pointer', display:'flex', alignItems:'center'}} onClick={() => {openReportUserModal()}}>
                    <div style={{width:'40px'}}>
                        <Image width={'30px'} src={warningIcon}/>
                    </div>
                    <div>
                        <Text color={colors.red} underline bold text={'Report User'}/>
                    </div>
                </div>
            </Row>
            <Modal
                isOpen={showReportModal}
                onRequestClose={() => setShowReportModal(false)}
                style={{
                    
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    },
                    content: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '400px',
                        height: '400px',
                        backgroundColor: 'white',
                        padding: '20px'
                    }
                }}
                contentLabel="Report User"
            >
                <Container>
                    <Row>
                        <Col>
                            <Text bold  text={'Report User'}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'20px'}}>
                        <Col>
                            <textarea onChange={(e) => setReportText(e.target.value)} style={{resize: 'none', borderRadius:'5px', width:'100%', height:'250px'}} placeholder='Reason'/>
                        </Col>
                    </Row>
                    <Row style={{marginTop:'10px'}}>
                        <Col>
                            <Button style={{border:'none', width:'100%', height:'35px', backgroundColor: colors.darkBlue}} onClick={() => {reportUser()}}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </>
    )
}


export default UserInfo