import React from 'react'
import { ITrackOption } from '../../../Models';
import { Col } from 'react-bootstrap';
import Text from '../../../Components/Shared/Text';
import Select from 'react-select'

const TrackFilter = ({trackList, trackFilter, setTrackFilter}:{trackList: ITrackOption[], trackFilter: ITrackOption|null, setTrackFilter:(v:ITrackOption|null)=>void}) => {
    return (
        <Col xs="3">
            <Text text={"Track Filter"}  />
            <Select
                isClearable
                isSearchable
                options={trackList}
                value={trackFilter}
                onChange={(v) => {
                    if (!v) {
                        setTrackFilter(null);
                        return;
                    }
                    let lt = trackList.find(
                        (x) => x.value === v?.value
                    );
                    if (lt) setTrackFilter(lt);
                }}
            />
        </Col>
    )
}

export default TrackFilter