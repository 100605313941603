import React, { useState } from 'react'
import { Page } from '../../Components/Shared/Page'
import { Col, Row } from 'react-bootstrap'
import { useUserStore } from '../../stores/UserStore'
import EditMode from './EditMode/EditMode'
import UserInfo from './UserInfo'
import LapTimesV2 from './LapTimes/LapTimes'

import "react-datepicker/dist/react-datepicker.css";

import "./datepicker.css"
const imgDefault = require('../../Assets/imgdefault.png')

export const Profile = () => {

    const user = useUserStore(state=>state.user)

    const [editMode, setEditMode] = useState(false)

    if(editMode) {
        return <Page>
            <EditMode setEditMode={setEditMode}/>
        </Page>
    }

    return (
        <Page>
            <Row>
                <Col xs={11}>
                    <div style={{height:'30vh', overflow:'hidden', borderRadius:'20px'}}>
                        <img src={user?.coverImageUrl ?? imgDefault} width={'100%'}/>
                    </div>
                </Col>
                <Col xs={1}/>
            </Row>
            <StandardMode setEditMode={setEditMode}/>
        </Page>
    )
}

const StandardMode = ({setEditMode}: {setEditMode: (v:boolean)=>void}) => {

    return (
        <Row style={{marginTop:'20px', padding:'10px'}}>
            <Col xs={11} >
                <Row>
                    <Col xs='4'>
                        <Row>
                            <Col xs='10'>
                                <UserInfo setEditMode={setEditMode}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs='8'>
                        <LapTimesV2/>
                    </Col>
                </Row>
            </Col>
            <Col xs={1}/>
        </Row>
    )
}
