import React, { useEffect, useState } from 'react'
import { useUserStore } from '../../../stores/UserStore';
import { useSupabaseClientStore } from '../../../stores/supabaseClientStore';
import useTrackListStore from '../../../stores/TracklistStore';
import { useToastStore } from '../../../stores/PopToastStore';
import { ILaptime, IRankedLaptime, ITrackOption } from '../../../Models';
import { IColumn } from '../../../Components/Shared/Table';
import Text from '../../../Components/Shared/Text';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { colors } from '../../../styles/colors';
import TrackFilter from './TrackFilter';
import RankFilter from './RankFilter';
import TimeScaleFilter from './TimeScaleFilter';
import SearchButton from './SearchButton';
import YourRank from './YourRank';
import DataTable from './DataTable';

const Leaderboards = () => {

    const user = useUserStore(state=>state.user)
    const supabase = useSupabaseClientStore((state) => state.supabaseClient);
    const trackList = useTrackListStore((state) => state.tracks);

    const popToast = useToastStore(state=>state.pop)

    const [fromRankFilter, setFromRankFilter] = useState(1)
    const [timeScale, setTimeScale] = useState<'AllTime'|'ThisYear'|'ThisMonth'>('AllTime')

    const [trackFilter, setTrackFilter] = useState<ITrackOption | null>(trackList[0]);

    const [leaderBoardResults, setLeaderBoardResults] = useState<IRankedLaptime[]>(
        []
    );

    const [userRankLaptime, setUserRankLaptime] = useState<IRankedLaptime|null>(null)

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(trackFilter) lookup()
    },[timeScale])

    const lookup = async () => {
        if(!trackFilter || !user) {
            popToast('No Track Selected', 'No Track')
            return
        }
        setLoading(true)
        
        let laptimes: IRankedLaptime[] = []
        if(timeScale === 'AllTime') {
            laptimes = (await supabase
                .from('Laptime')
                .select('*')
                .eq('track', trackFilter.id)
                .order('time',{ascending: true})).data ?? []
                
            laptimes = laptimes.map((x, i) => {
                return {
                    ...x,
                    rank: i + 1,
                    of: laptimes.length
                }
            })

            // setLeaderBoardResults(laptimes.slice(fromRankFilter - 1, fromRankFilter + 10))
            setUserRankLaptime(laptimes.find((x) => x.user === user.id) ?? null)
        }

        if(timeScale === 'ThisYear') {
            const today = new Date()
            const d = `01/01/${today.getFullYear()}`
            laptimes = (await supabase
                .from('Laptime')
                .select('*')
                .eq('track', trackFilter.id)
                .filter('dateSet', 'gte', d)
                .order('time',{ascending: true})).data ?? []
               
            laptimes = laptimes.map((x, i) => {
                return {
                    ...x,
                    rank: i + 1,
                    of: laptimes.length
                }
            })

            // setLeaderBoardResults(laptimes.slice(fromRankFilter - 1, fromRankFilter + 10))
            setUserRankLaptime(laptimes.find((x) => x.user === user.id) ?? null)

        }

        if(timeScale === 'ThisMonth') {
            const today = new Date()
            let month = String(today.getMonth() + 1)
            if(Number(month) < 10) month = `0${month}`
            const d = `${month}/01/${today.getFullYear()}`
            laptimes = (await supabase
                .from('Laptime')
                .select('*')
                .eq('track', trackFilter.id)
                .filter('dateSet', 'gte', d)
                .order('time',{ascending: true})).data ?? []
               
            laptimes = laptimes.map((x, i) => {
                return {
                    ...x,
                    rank: i + 1,
                    of: laptimes.length
                }
            })

            // setLeaderBoardResults(laptimes.slice(fromRankFilter - 1, fromRankFilter + 10))
            setUserRankLaptime(laptimes.find((x) => x.user === user.id) ?? null)
        }

        const users =
            (
                await supabase
                    .from("Profiles")
                    .select("*")
                    .in(
                        "id",
                        laptimes.map((x) => x.user)
                    )
            ).data ?? [];

        // get verification counts of each laptime
        let verificationsResult =
            (
                await supabase
                    .from("LaptimeVerification")
                    .select("*")
                    .in(
                        "laptime",
                        laptimes.map((x) => x.id)
                    )
            ).data ?? [];

        // join verifications and laptimes onto laptimesResult
        laptimes = laptimes.map((x) => {
            const usr = users.find((y) => y.id === x.user);
            return {
                ...x,
                dateSet: x.dateSet
                    ? new Date(x.dateSet).toLocaleDateString()
                    : "",
                userName: `${usr?.name} "${usr?.nickname}"`,
                trackName: trackList.find((y) => y.id === x.track)?.name ?? "",
                verifications:
                    verificationsResult.filter((y) => y.laptime === x.id)
                        .length ?? 0,
                userHasVerified: verificationsResult.find(y => y.laptime === x.id && y.user === user.id) === undefined ? false : true
            };
        });

        setLeaderBoardResults(laptimes.slice(fromRankFilter - 1, fromRankFilter + 10))
        setLoading(false)
    }

    const handleVerify = async (laptimeId: number) => {
        if(!user) return
        // check if verification record exists
        let verified = (await supabase.from('LaptimeVerification').select('*').eq('user', user.id).eq('laptime', laptimeId)).data?.length ?? 0

        if(verified > 0) {
            popToast('Laptime Already Verified', 'Already Verified')
            return
        }

        // if no record - create record
        await supabase.from('LaptimeVerification').insert({
            user: user.id,
            laptime: laptimeId
        })

        // clear verification requested record
        await supabase.from('VerificationRequest').delete().eq('laptimeId', laptimeId)

        popToast('Laptime Verified, Thankyou!', 'Verified')

        await lookup()
    }

    const columns: IColumn<IRankedLaptime>[] = [
        {
            title: "User",
            accessor: "userName",
            width: 3,
            cell: (rowData: IRankedLaptime) => <Text  align="left" text={rowData.userName} />,
        },
        {
            title: "Rank",
            accessor: "trackName",
            width: 2,
            cell: (rowData: IRankedLaptime) => <Text  align="left" text={`${rowData.rank}/${rowData.of}`} />,
        },
        {
            title: "Laptime",
            accessor: "time",
            width: 2,
            cell: (rowData: IRankedLaptime) => <Text  align="left" text={rowData.time?.toString()} />,
        },
        {
            title: "Date Set",
            accessor: "dateSet",
            width: 2,
            cell: (rowData: IRankedLaptime) => <Text  align="left" text={rowData.dateSet} />,
        },
        {
            title: "Verifications",
            accessor: "verifications",
            width: 2,
            cell: (rowData: IRankedLaptime) => {
                return <Text  align="left" text={rowData.verifications?.toString()} />;
            },
        },
        {
            title: "-",
            accessor: "verify",
            width: 1,
            cell: (rowData: ILaptime) => {
                if(rowData.userHasVerified || rowData.user === user?.id) return <></>
                else return <Button style={{border:'none', width:'100%',height:'100%', display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:colors.darkBlue, fontSize:14}} onClick={() => handleVerify(rowData.id)}>Verify Lap</Button>
            }
        }
    ];

    return (
        <div style={{marginTop:'20px', padding:'20px', height:'70vh', backgroundColor:colors.white, width:'100%', borderRadius:'20px', paddingTop:'10px',  boxShadow: '1px 2px 9px #F4AAB9'}}>
            <Container fluid>
                
                <Row>
                    
                    <TrackFilter trackFilter={trackFilter} trackList={trackList} setTrackFilter={setTrackFilter}/>
                    
                    <RankFilter fromRankFilter={fromRankFilter} setFromRankFilter={setFromRankFilter}/>
                    
                    <TimeScaleFilter timeScale={timeScale} setTimeScale={setTimeScale}/>
                    
                    <SearchButton lookup={lookup}/>
                    
                    <Col xs="2" />

                </Row>
                
                <YourRank loading={loading} userRankLaptime={userRankLaptime} timeScale={timeScale} trackFilter={trackFilter}/>
                
                <DataTable columns={columns} leaderBoardResults={leaderBoardResults} loading={loading}/>
            
            </Container>
        </div>
    )
}

export default Leaderboards